import React, { ReactElement } from 'react';
import GlobeSection from '../sections/lobby/globe';
import GlobeDataProvider from '../providers/globe-data-provider';
import MobileLobbyHeader from '../sections/lobby/mobile-lobby-header';
import GlobeUiContainer from '../sections/lobby/globe-ui-container';

export default function Lobby(): ReactElement {
    return (
        <GlobeDataProvider>
            <div className='flex h-screen w-screen'>
                <MobileLobbyHeader />
                <GlobeSection />
                <GlobeUiContainer />
            </div>
        </GlobeDataProvider>
    );
}

import React, { ReactElement } from 'react';
import { Popover, Transition } from '@headlessui/react';
import Class from '../../utils/classes';

type Props = {
    shortName: string;
    fullname: string;
    className?: string;
};

export default function UserAvatar(props: Props): ReactElement {
    const { shortName, fullname, className } = props;

    return (
        <Popover className='relative'>
            {({ open }) => (
                <>
                    <Popover.Button
                        className={Class.classNames(
                            'item-center flex h-10 w-10 justify-center rounded-full border-2 border-white shadow focus:ring-0 active:ring-0',
                            open ? 'bg-company-green' : 'bg-gradient-to-t from-[#D9D9D9] to-[#F4F4F4]',
                            className || '',
                        )}
                    >
                        <p className='m-auto text-base text-black'>{shortName}</p>
                    </Popover.Button>

                    <Transition
                        appear
                        show={open}
                        enter='ease-out duration-200'
                        enterFrom='opacity-0 translate-y-1'
                        enterTo='opacity-100 translate-y-0'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Popover.Panel className='absolute z-10 my-2 flex w-48 -translate-x-1/2 flex-col rounded-xl bg-company-darkGrey p-4 text-white'>
                            <p className='w-full font-semibold antialiased'>{fullname}</p>
                            <div className='item-center flex space-x-2'>
                                <div className='my-auto h-2 w-2 rounded-full bg-green-500' />
                                <p>Online now</p>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
}

UserAvatar.defaultProps = {
    className: '',
};

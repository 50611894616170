import React, { createContext, ReactElement, useMemo } from 'react';
import { Word } from '../types/word';
import { Types } from 'ably';
import { useChannel } from '@ably-labs/react-hooks';
import useSession from '../hooks/use-session';
import useStateRef from 'react-usestateref';
import Channels from '../types/enum/channels';
import useUser from '../hooks/use-user';

type GameStateContextType = {
    allWords: Word[];
    setAllWords(allWords: Word[]): void;
    gameCanvasWords: Word[];
    setGameCanvasWords(gameCanvasWords: Word[]): void;
};

export const GameStateContext = createContext<GameStateContextType>({
    allWords: [],
    setAllWords: () => {},
    gameCanvasWords: [],
    setGameCanvasWords: () => {},
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function GameStateProvider(props: Props): ReactElement {
    const { children } = props;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setIsInitialized, isInitializedRef] = useStateRef(false);
    const [allWords, setAllWords, allWordsRef] = useStateRef<Word[]>([]);
    const [gameCanvasWords, setGameCanvasWords, gameCanvasWordsRef] = useStateRef<Word[]>([]);

    const {
        session: { id: sessionId },
    } = useSession();
    const user = useUser();

    function syncGameState(): void {
        const data = {
            allWords: allWordsRef.current.map((item) => `${item.id}#${item.text}`),
            gameCanvasWords: gameCanvasWordsRef.current.map(
                (item) => `${item.id}#${item.position.x}#${item.position.y}`,
            ),
        };

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        gameStateChannel.publish('game-sync', data);
    }

    function handleUpdateWordPosition(msg: Types.Message): void {
        const { clientId: msgClientId, wordId, x, y } = msg.data;
        const word = allWordsRef.current.find((item) => item.id === wordId);

        if (!word) {
            return;
        }

        const updatedWord = gameCanvasWordsRef.current.map((item) => {
            if (item.id === word.id) {
                return {
                    ...item,
                    position: {
                        x,
                        y,
                    },
                };
            }

            return item;
        });

        setGameCanvasWords(updatedWord);

        if (msgClientId === user.id) {
            syncGameState();
        }
    }

    function handleAddWordToCanvasEvent(msg: Types.Message): void {
        const { clientId: msgClientId, wordId, x, y } = msg.data;
        const word = allWordsRef.current.find((item) => item.id === wordId);
        if (!word) {
            return;
        }

        setGameCanvasWords([
            ...gameCanvasWordsRef.current,
            {
                ...word,
                position: { x: x || 0, y: y || 0 },
            },
        ]);

        if (msgClientId === user.id) {
            syncGameState();
        }
    }

    function handleRemoveWordFromCanvasEvent(msg: Types.Message): void {
        const { clientId: msgClientId, wordId } = msg.data;
        const updatedWords = gameCanvasWordsRef.current.filter((item) => item.id !== wordId);
        setGameCanvasWords(updatedWords);

        if (msgClientId === user.id) {
            syncGameState();
        }
    }

    function handleAddWordToWordbar(msg: Types.Message): void {
        const { clientId: msgClientId, focus, items } = msg.data;

        items.forEach((item: { wordId: string; wordText: string; randomIndex: number }) => {
            const { wordText, wordId, randomIndex } = item;

            const allWordsArray = allWordsRef.current.slice();

            allWordsArray.splice(randomIndex, 0, {
                id: wordId,
                text: wordText,
                focus: msgClientId === user.id ? focus : false,
                position: {
                    x: 0,
                    y: 0,
                },
            });

            setAllWords(allWordsArray);
        });

        if (msgClientId === user.id) {
            syncGameState();
        }
    }

    function handleGameStateSync(msg: Types.Message): void {
        if (isInitializedRef.current) {
            return;
        }

        const { gameCanvasWords: initGameCanvasWords, allWords: initAllWords } = msg.data;

        const allWordsArray = initAllWords.map((item: string) => {
            const [id, text] = item.split('#');
            return {
                id,
                text,
                position: {
                    x: 0,
                    y: 0,
                },
            };
        });

        setAllWords(allWordsArray);

        const gameCanvasWordsArray = initGameCanvasWords.map((item: string) => {
            const [id, x, y] = item.split('#');
            return {
                id,
                text: allWordsArray.find((el: Word) => el.id === id)?.text || '',
                position: {
                    x: Number(x),
                    y: Number(y),
                },
            };
        });

        setGameCanvasWords(gameCanvasWordsArray || []);
        setIsInitialized(true);
    }

    function handleMarkWordAsFocused(msg: Types.Message): void {
        const { clientId: msgClientId, wordId } = msg.data;
        if (msgClientId !== user.id) {
            return;
        }

        const updatedWords = allWordsRef.current.map((item) => {
            if (item.id === wordId) {
                return {
                    ...item,
                    focus: false,
                };
            }

            return item;
        });

        setAllWords(updatedWords);
    }

    const channelName = `${Channels.GameCanvas}:${sessionId}`;
    useChannel(channelName, 'word-added', handleAddWordToCanvasEvent);
    useChannel(channelName, 'word-position-updated', handleUpdateWordPosition);
    useChannel(channelName, 'word-removed', handleRemoveWordFromCanvasEvent);
    useChannel(channelName, 'wordbar-updated', handleAddWordToWordbar);
    useChannel(channelName, 'word-focus-updated', handleMarkWordAsFocused);

    const gameStateChannelName = `[?rewind=1]${Channels.GameStates}:${sessionId}`;
    const [gameStateChannel] = useChannel(gameStateChannelName, 'game-sync', handleGameStateSync);

    const value = useMemo(
        () => ({
            allWords,
            setAllWords,

            gameCanvasWords,
            setGameCanvasWords,
        }),
        [allWords, gameCanvasWords],
    );

    return <GameStateContext.Provider value={value}>{children}</GameStateContext.Provider>;
}

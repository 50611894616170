export default function GlobeDataCenter(data: any, onSelect: (e: any) => void): HTMLDivElement {
    const el = document.createElement('div');

    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgElement.setAttribute('width', '51');
    svgElement.setAttribute('height', '66');
    svgElement.setAttribute('viewBox', '0 0 51 66');
    svgElement.setAttribute('fill', 'none');

    const gElement = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    gElement.setAttribute('filter', 'url(#filter0_dd_1320_12649)');

    const pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    pathElement.setAttribute(
        'd',
        'M25.1583 4.52637C13.6538 4.52637 4.29492 13.8026 4.29492 25.2055C4.29492 39.3562 22.9654 60.1301 23.7597 61.0078C24.5068 61.8319 25.8097 61.8308 26.5557 61.0078C27.3512 60.1301 46.0205 39.3562 46.0205 25.2055C46.0205 13.8037 36.6628 4.52637 25.1583 4.52637Z',
    );
    pathElement.setAttribute('fill', '#32C361');

    gElement.append(pathElement);

    const defsElement = document.createElementNS('http://www.w3.org/2000/svg', 'defs');

    const filterElement = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
    filterElement.setAttribute('id', 'filter0_dd_1320_12649');
    filterElement.setAttribute('x', '0.294922');
    filterElement.setAttribute('y', '0.526367');
    filterElement.setAttribute('width', '49.7256');
    filterElement.setAttribute('height', '65.0991');
    filterElement.setAttribute('filterUnits', 'userSpaceOnUse');
    filterElement.setAttribute('color-interpolation-filters', 'sRGB');

    const filterChildren = [
        '<feFlood flood-opacity="0" result="BackgroundImageFix"/>',
        '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>',
        '<feOffset/>',
        '<feGaussianBlur stdDeviation="2"/>',
        '<feComposite in2="hardAlpha" operator="out"/>',
        '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>',
        '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1320_12649"/>',
        '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>',
        '<feOffset/>',
        '<feGaussianBlur stdDeviation="2"/>',
        '<feComposite in2="hardAlpha" operator="out"/>',
        '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>',
        '<feBlend mode="normal" in2="effect1_dropShadow_1320_12649" result="effect2_dropShadow_1320_12649"/>',
        '<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1320_12649" result="shape"/>',
    ];

    filterElement.innerHTML = filterChildren.join('');

    defsElement.append(filterElement);

    svgElement.append(gElement);
    svgElement.append(defsElement);

    svgElement.style.transform = 'translate(0, -50%)';

    el.append(svgElement);

    const text = document.createElement('p');
    text.innerHTML = 'DC';
    text.style.display = 'flex';
    text.style.position = 'absolute';
    text.style.color = '#000000';
    text.style.fontSize = `14px`;
    text.style.fontWeight = 'bold';
    text.style.fontFamily = 'NEXT';
    text.style.textAlign = 'center';
    text.style.zIndex = '50';
    text.style.transform = 'translate(-50%, -75%)';
    text.style.top = '0';
    text.style.left = '50%';

    el.style.pointerEvents = 'auto';
    el.style.cursor = 'pointer';
    el.addEventListener('click', () => onSelect(data));

    el.append(text);

    return el;
}

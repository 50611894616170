import React, { ReactElement, useEffect } from 'react';

export default function RequestDemoSessionPage(): ReactElement {
    useEffect(() => {
        // Note: Redirecting user to the request demo session, which is not part of this project.
        // window.location.href = os.getEnv('REACT_APP_REQUEST_DEMO_SESSION_URL');
        // Note: This is a temporary redirect to the Ably homepage. For Demo purposes only.
        window.location.href = 'https://www.ably.io';
    }, []);

    return <></>;
}

import React, { ReactElement } from 'react';
import useLocale from '../../hooks/use-locale';

export default function WelcomeText(): ReactElement {
    const { getText } = useLocale();

    return (
        <section className='flex'>
            <div>
                <h1 className='font-bold'>{getText('pages.login.title')}</h1>
                <p>{getText('pages.login.copy')}</p>
            </div>
        </section>
    );
}

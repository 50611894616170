import { UserRegionPoint } from '../../types/globe';
import { User } from '../../types/user';

export default function GlobeUserMarker(
    onClick: (e: any) => void,
    data: UserRegionPoint,
    selected?: User,
): HTMLDivElement {
    const { users } = data;

    const isSelected = users.find((user) => user.id === selected?.id);

    const label = document.createElement('p');
    label.innerHTML = `${users[0].shortName.toUpperCase()}`;
    label.style.color = '#000';
    label.style.fontSize = `14px`;
    label.style.fontWeight = 'bold';
    label.style.textAlign = 'center';
    label.style.marginTop = '50%';
    label.style.transform = 'translateY(-50%)';

    const el = document.createElement('div');
    el.style.color = '#000';
    el.style.backgroundColor = isSelected ? '#32C361' : '#fff';
    el.style.borderRadius = '100%';
    el.style.width = `40px`;
    el.style.height = `40px`;
    el.style.pointerEvents = 'auto';
    el.style.cursor = 'pointer';

    el.style.zIndex = '110';

    el.append(label);

    if (users.length === 1) {
        el.addEventListener('click', () => onClick(data));
    }

    return el;
}

import React, { ReactElement, useEffect, useState } from 'react';
import { useDraggable } from '@dnd-kit/core';
import UserMarkBadge from '../../users/user-mark-badge';
import useWordMarker from '../../../hooks/use-word-marker';
import { WordSelection } from '../../../types/word';
import useUser from '../../../hooks/use-user';

type Props = {
    id: string;
    children: ReactElement;
    position: {
        x: number;
        y: number;
    };
    className: string | undefined;
};

export default function GameCanvasWordItem(props: Props): ReactElement {
    const { id, children, position, className } = props;
    const { selections } = useWordMarker();

    const [selection, setSelection] = useState<WordSelection>();
    const user = useUser();
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id,
    });

    useEffect(() => {
        const wordId = id.replace('id-game-canvas-', '');
        const item = selections.find((s) => s.wordId === wordId);
        setSelection(item);
    }, [selections]);

    return (
        <div
            ref={setNodeRef}
            style={{
                position: 'absolute',
                left: `${position.x}px`,
                top: `${position.y}px`,
            }}
            {...attributes}
            {...listeners}
            {...{
                x: transform?.x,
                y: transform?.y,
                scalex: transform?.scaleX,
                scaley: transform?.scaleY,
            }}
            className={className || ''}
        >
            {user.id !== selection?.clientId && (
                <UserMarkBadge
                    name={selection?.name || ''}
                    className='absolute top-0 right-0 translate-x-1/2 -translate-y-1/2'
                />
            )}
            {children}
        </div>
    );
}

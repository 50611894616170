/* eslint-disable unicorn/numeric-separators-style */
const arcsData = [
    {
        startLat: 39.982867,
        startLng: -83.1309094,
        endLat: 38.852134,
        endLng: -77.336851,
    },
    {
        startLat: 39.982867,
        startLng: -83.1309094,
        endLat: 37.757687,
        endLng: -122.4551093,
    },
    {
        startLat: 39.982867,
        startLng: -83.1309094,
        endLat: 53.3239756,
        endLng: -6.5327525,
    },
    {
        startLat: 39.982867,
        startLng: -83.1309094,
        endLat: 50.1211909,
        endLng: 8.566525,
    },
    {
        startLat: 39.982867,
        startLng: -83.1309094,
        endLat: -33.8481647,
        endLng: 150.7918939,
    },
    {
        startLat: 39.982867,
        startLng: -83.1309094,
        endLat: 1.313996,
        endLng: 103.7041681,
    },
    {
        startLat: 38.852134,
        startLng: -77.336851,
        endLat: 37.757687,
        endLng: -122.4551093,
    },
    {
        startLat: 38.852134,
        startLng: -77.336851,
        endLat: 53.3239756,
        endLng: -6.5327525,
    },
    {
        startLat: 38.852134,
        startLng: -77.336851,
        endLat: 50.1211909,
        endLng: 8.566525,
    },
    {
        startLat: 38.852134,
        startLng: -77.336851,
        endLat: -33.8481647,
        endLng: 150.7918939,
    },
    {
        startLat: 38.852134,
        startLng: -77.336851,
        endLat: 1.3139961,
        endLng: 103.7041681,
    },
    {
        startLat: 37.757687,
        startLng: -122.4551093,
        endLat: 53.3239756,
        endLng: -6.5327525,
    },
    {
        startLat: 37.757687,
        startLng: -122.4551093,
        endLat: 50.1211909,
        endLng: 8.566525,
    },
    {
        startLat: 37.757687,
        startLng: -122.4551093,
        endLat: -33.8481647,
        endLng: 150.7918939,
    },
    {
        startLat: 37.757687,
        startLng: -122.4551093,
        endLat: 1.3139961,
        endLng: 103.7041681,
    },
    {
        startLat: 53.3239756,
        startLng: -6.5327525,
        endLat: 50.1211909,
        endLng: 8.566525,
    },
    {
        startLat: 53.3239756,
        startLng: -6.5327525,
        endLat: -33.8481647,
        endLng: 150.7918939,
    },
    {
        startLat: 53.3239756,
        startLng: -6.5327525,
        endLat: 1.3139961,
        endLng: 103.7041681,
    },
    {
        startLat: 50.1211909,
        startLng: 8.566525,
        endLat: -33.8481647,
        endLng: 150.7918939,
    },
    {
        startLat: 50.1211909,
        startLng: 8.566525,
        endLat: 1.3139961,
        endLng: 103.7041681,
    },
    {
        startLat: -33.8481647,
        startLng: 150.7918939,
        endLat: 1.3139961,
        endLng: 103.7041681,
    },
];

export default arcsData;

import React, { ReactElement, useState } from 'react';
import useSession from '../../hooks/use-session';
import Logo from './logo';
import useLocale from '../../hooks/use-locale';
import Class from '../../utils/classes';
import CloseIcon from '../../components/icons/close';
import VerticalMoreIcon from '../../components/icons/vertical-more';
import StateToggle from '../../components/state-toggle';
import Chevron from '../../components/icons/chevron';
import QRCodeViewer from '../../components/qr-code';

export default function MobileLobbyHeader(): ReactElement {
    const [expanded, setExpanded] = useState(false);

    const { isModerator, session } = useSession();
    const baseUrl = window.location.href.split('/')[2];

    const { getText } = useLocale();

    function onClick(): void {
        setExpanded(!expanded);
    }

    return (
        <div
            className={Class.classNames(
                'absolute z-[100] w-full space-y-20 pt-5 md:hidden',
                expanded ? 'pointer-events-auto h-full bg-black py-5' : 'pointer-events-none h-20 ',
            )}
        >
            <div className='flex justify-center'>
                <Logo className='flex w-full' />
                {isModerator && (
                    <button
                        type='button'
                        onClick={() => onClick()}
                        className='pointer-events-auto absolute right-5 h-fit w-fit'
                    >
                        {expanded ? (
                            <CloseIcon className='h-8 w-8 fill-white' />
                        ) : (
                            <VerticalMoreIcon className='h-8 w-8 fill-white pt-1' />
                        )}
                    </button>
                )}
            </div>
            {expanded && (
                <div className='relative h-fit w-full flex-col justify-center md:my-auto'>
                    <div className='m-auto max-w-[300px] space-y-5'>
                        <div className='mx-auto w-fit'>
                            <StateToggle theme='dark' />
                        </div>
                        <div className='flex h-fit flex-col items-center justify-center space-y-5 rounded-lg bg-company-darkGrey p-6 text-center text-white'>
                            <div>
                                <h2 className='font-bold'>{getText('pages.landing.qrModal.title')}</h2>
                                <p className='text-base'>{getText('pages.landing.qrModal.copy')}</p>
                            </div>
                            <span className='align-middle font-bold text-company-blue'>
                                <Chevron className='mr-2 inline-flex h-4 w-4 fill-company-blue' />
                                <span>
                                    {baseUrl}/{session.id}
                                </span>
                            </span>
                            <div className='w-1/2'>
                                <QRCodeViewer theme='dark' sessionPath={window.location.href} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

import React, { ReactElement } from 'react';
import { IconProps } from '../../types/icons';

export default function PlusIcon(props: IconProps): ReactElement {
    const { width, height } = props;

    return (
        <svg width={width} height={height} viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M6.20325 3.2687H4.08674V1.15219C4.08674 0.860106 3.85022 0.623047 3.5576 0.623047C3.26499 0.623047 3.02846 0.860106 3.02846 1.15219V3.2687H0.911953C0.619338 3.2687 0.382812 3.50576 0.382812 3.79784C0.382812 4.08992 0.619338 4.32698 0.911953 4.32698H3.02846V6.44349C3.02846 6.73557 3.26499 6.97263 3.5576 6.97263C3.85022 6.97263 4.08674 6.73557 4.08674 6.44349V4.32698H6.20325C6.49587 4.32698 6.73239 4.08992 6.73239 3.79784C6.73239 3.50576 6.49587 3.2687 6.20325 3.2687Z'
                fill='black'
                stroke='black'
                strokeWidth='0.3'
            />
        </svg>
    );
}

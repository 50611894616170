import { IconProps } from '../../types/icons';
import React, { ReactElement } from 'react';

export default function ArrowWithCircle(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 21 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
        >
            <path
                d='M9.84062 12.8707L8.30111 11.3312C7.97229 11.0024 7.43918 11.0024 7.11037 11.3312C6.78152 11.66 6.78152 12.1931 7.11034 12.522L10.0872 15.4989C10.1068 15.5185 10.1275 15.5371 10.149 15.5547C10.1586 15.5626 10.1688 15.5695 10.1788 15.5769C10.1908 15.5859 10.2026 15.5953 10.2151 15.6037C10.2271 15.6117 10.2395 15.6186 10.2518 15.6259C10.263 15.6327 10.2741 15.6398 10.2857 15.646C10.2983 15.6527 10.3112 15.6584 10.324 15.6644C10.3361 15.6701 10.348 15.6762 10.3604 15.6813C10.3729 15.6865 10.3856 15.6907 10.3982 15.6951C10.4115 15.6999 10.4246 15.705 10.4382 15.7091C10.4509 15.713 10.4637 15.7157 10.4765 15.719C10.4903 15.7225 10.504 15.7264 10.5181 15.7292C10.5329 15.7321 10.5478 15.7338 10.5626 15.736C10.5749 15.7377 10.5871 15.7401 10.5995 15.7413C10.6548 15.7467 10.7105 15.7467 10.7657 15.7413C10.7782 15.7401 10.7903 15.7377 10.8026 15.7359C10.8175 15.7338 10.8324 15.7321 10.8472 15.7291C10.8613 15.7263 10.8749 15.7225 10.8888 15.719C10.9016 15.7158 10.9144 15.713 10.9271 15.7091C10.9407 15.705 10.9537 15.6999 10.9671 15.6951C10.9797 15.6906 10.9924 15.6865 11.0049 15.6813C11.0173 15.6762 11.0292 15.6701 11.0413 15.6644C11.0541 15.6584 11.067 15.6527 11.0796 15.646C11.0912 15.6398 11.1022 15.6327 11.1135 15.6259C11.1258 15.6186 11.1382 15.6117 11.1502 15.6037C11.1627 15.5953 11.1745 15.5859 11.1865 15.5769C11.1964 15.5695 11.2067 15.5626 11.2163 15.5547C11.2377 15.537 11.2584 15.5185 11.278 15.4989L14.2549 12.522C14.5837 12.1931 14.5837 11.66 14.2549 11.3312C13.9261 11.0024 13.393 11.0024 13.0642 11.3312L11.5246 12.8707L11.5246 5.37743C11.5246 4.91242 11.1477 4.53543 10.6826 4.53543C10.2176 4.53543 9.84065 4.91242 9.84065 5.37743L9.84062 12.8707Z'
                fill='#32C361'
            />
            <circle
                cx='10.6827'
                cy='10.1404'
                r='9.02214'
                transform='rotate(-180 10.6827 10.1404)'
                stroke='#32C361'
                strokeWidth='1.92422'
            />
        </svg>
    );
}

import React, { ReactElement } from 'react';
import Class from '../../utils/classes';
import GlobeSelectionType from '../../types/enum/globe-selection';
import useGlobeData from '../../hooks/use-globe-data';
import CloseIcon from '../../components/icons/close';
import Sizes from '../../constants/sizes';
import StatisticsBlock from './statistics-block';
import useAblyRegionStats from '../../hooks/use-ably-region-stats';
import { DataCenters } from '../../constants/data-centers';

type StatisticsGroupProps = {
    regionId: string;
};

function StatisticsGroup(props: StatisticsGroupProps): ReactElement {
    const { regionId } = props;

    const { stats } = useAblyRegionStats(regionId);

    return (
        <div className='flex flex-col'>
            <StatisticsBlock
                id={`${regionId}-connection`}
                size='small'
                name='Connections'
                values={stats.activeConnections.previousRecords}
                digits={3}
            />
            <StatisticsBlock
                id={`${regionId}-channels`}
                size='small'
                name='Channels'
                values={stats.activeChannels.previousRecords}
                digits={3}
            />
            <StatisticsBlock
                id={`${regionId}-messages`}
                size='small'
                name='Messages'
                values={stats.msgPerSec.previousRecords}
                suffix='/s'
                digits={3}
            />
        </div>
    );
}

export default function SelectedRegionStatisticCard(): ReactElement {
    const { selection, clearSelection } = useGlobeData();

    function handleClose(): void {
        clearSelection();
    }

    return (
        <section
            className={Class.classNames(
                'z-50 mx-auto h-fit w-full max-w-sm space-y-5 rounded-xl bg-company-darkGrey p-5 shadow-xl',
                selection.state === GlobeSelectionType.Region ? '' : 'hidden',
            )}
        >
            <div className='flex justify-between'>
                <div className='flex flex-col'>
                    <p className='text-xs uppercase text-company-green'>Data Center</p>
                    <h2 className='text-xl font-semibold antialiased text-company-green'>{`${
                        DataCenters.find((dc) => dc.region_id.toLowerCase() === selection.regionId?.toLowerCase())
                            ?.label || ''
                    }`}</h2>
                </div>
                <button type='button' className='pointer-events-auto mb-auto' onClick={() => handleClose()}>
                    <CloseIcon className='fill-white' width={Sizes.icon.button} height={Sizes.icon.button} />
                </button>
            </div>
            {selection.state === GlobeSelectionType.Region && <StatisticsGroup regionId={selection.regionId || ''} />}
        </section>
    );
}

import { Word } from '../types/word';
import { useContext } from 'react';
import { GameStateContext } from '../providers/game-state-provider';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useChannel } from '@ably-labs/react-hooks';
import useSession from './use-session';
import Channels from '../types/enum/channels';
import { nanoid } from 'nanoid';
import useUser from './use-user';

type GameStateApi = {
    allWords: Word[];
    gameCanvasWords: Word[];
    appendWordToGameCanvas(wordId: UniqueIdentifier, x?: number, y?: number): void;
    removeWordFromGameCanvas(wordId: UniqueIdentifier): void;
    updateWordPosition(wordId: UniqueIdentifier, x: number, y: number): void;
    addWordToWordbar(wordText: string, wordId: string, randomIndex: number): void;
    addWord(text: string): void;
    markWordAsFocus(wordId: UniqueIdentifier): void;
};

export default function useGameState(): GameStateApi {
    const { gameCanvasWords, allWords } = useContext(GameStateContext);

    const { session } = useSession();
    const user = useUser();

    const channelName = `${Channels.GameCanvas}:${session.id}`;
    const [channel] = useChannel(channelName, () => {});

    function appendWordToGameCanvas(wordId: UniqueIdentifier, x?: number, y?: number): void {
        const id = wordId.toString().replace('wordbar-', '');
        const word = allWords.find((item) => item.id === id);

        if (!word) {
            return;
        }

        const data = {
            clientId: user.id,
            wordId: word.id,
            x,
            y,
        };

        channel.publish('word-added', data);
    }

    function removeWordFromGameCanvas(wordId: UniqueIdentifier): void {
        const id = wordId.toString().replace('id-game-canvas-', '');
        const word = allWords.find((item) => item.id === id);

        if (!word) {
            return;
        }

        const data = {
            clientId: user.id,
            wordId: word.id,
        };

        channel.publish('word-removed', data);
    }

    function updateWordPosition(wordId: UniqueIdentifier, deltaX: number, deltaY: number): void {
        const id = wordId.toString().replace('id-game-canvas-', '');
        const word = allWords.find((item) => item.id === id);

        if (!word) {
            return;
        }

        const data = {
            clientId: user.id,
            wordId: word.id,
            x: deltaX,
            y: deltaY,
        };

        channel.publish('word-position-updated', data);
    }

    function addWordToWordbar(wordText: string, wordId: string, randomIndex: number, focus?: boolean): void {
        const data = {
            clientId: user.id,
            focus,
            items: [
                {
                    wordText,
                    wordId,
                    randomIndex,
                },
            ],
        };

        channel.publish('wordbar-updated', data);
    }

    function addWord(text: string): void {
        const randomIndex = Math.floor(Math.random() * allWords.length);
        addWordToWordbar(text, `c$${nanoid(4)}`, randomIndex, true);
    }

    function markWordAsFocus(wordId: UniqueIdentifier): void {
        const id = wordId.toString().replace('wordbar-', '');
        const word = allWords.find((item) => item.id === id);

        if (!word) {
            return;
        }

        const data = {
            clientId: user.id,
            wordId: word.id,
        };

        channel.publish('word-focus-updated', data);
    }

    return {
        allWords,
        gameCanvasWords,
        appendWordToGameCanvas,
        removeWordFromGameCanvas,
        updateWordPosition,
        addWordToWordbar,
        addWord,
        markWordAsFocus,
    };
}

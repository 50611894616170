import { useState } from 'react';

type ThrottleApi = {
    throttle(fn: Function, delay: number): Function;
};

export default function useThrottling(): ThrottleApi {
    const [lastCall, setLastCall] = useState(0);

    function throttle(fn: Function, delay: number): Function {
        return (...args: any[]) => {
            const now = Date.now();

            if (now - lastCall < delay) {
                return;
            }

            setLastCall(now);

            // eslint-disable-next-line consistent-return
            return fn(...args);
        };
    }

    return {
        throttle,
    };
}

import { useChannel } from '@ably-labs/react-hooks';
import { Types } from 'ably';
import { useEffect, useState } from 'react';
import { DataCenters } from '../constants/data-centers';
import { AblyStatEventValue } from '../types/ably';

type AblyRegionStats = {
    region: {
        id: string;
        name: string;
    };
    stats: {
        msgPerSec: {
            current: number;
            previousRecords: number[];
        };
        activeChannels: {
            current: number;
            previousRecords: number[];
        };
        // front router connections
        activeConnections: {
            current: number;
            previousRecords: number[];
        };
    };
};

const maxHistoryLength = 10;

const defaultValue: AblyRegionStats = {
    region: {
        id: '',
        name: '',
    },
    stats: {
        msgPerSec: {
            current: 0,
            previousRecords: [],
        },
        activeChannels: {
            current: 0,
            previousRecords: [],
        },
        activeConnections: {
            current: 0,
            previousRecords: [],
        },
    },
};

export default function useAblyRegionStats(regionId: any): AblyRegionStats {
    const [ablyStats, setAblyStats] = useState<AblyRegionStats>(defaultValue);

    function handleMsgPerSecEvent(event: AblyStatEventValue): void {
        const { Value: value } = event;

        setAblyStats((prevState) => ({
            ...prevState,
            stats: {
                ...prevState.stats,
                msgPerSec: {
                    current: value,
                    previousRecords: [...prevState.stats.msgPerSec.previousRecords, value].slice(-maxHistoryLength),
                },
            },
        }));
    }

    function handleChannelsActiveEvent(event: AblyStatEventValue): void {
        const { Value: value } = event;

        setAblyStats((prevState) => ({
            ...prevState,
            stats: {
                ...prevState.stats,
                activeChannels: {
                    current: value,
                    previousRecords: [...prevState.stats.activeChannels.previousRecords, value].slice(
                        -maxHistoryLength,
                    ),
                },
            },
        }));
    }

    function handleFrontRouterConnectionsActiveEvent(event: AblyStatEventValue): void {
        const { Value: value } = event;

        setAblyStats((prevState) => ({
            ...prevState,
            stats: {
                ...prevState.stats,
                activeConnections: {
                    current: value,
                    previousRecords: [...prevState.stats.activeConnections.previousRecords, value].slice(
                        -maxHistoryLength,
                    ),
                },
            },
        }));
    }

    function handleAblyStatsEvent(event: Types.Message): void {
        const { Values: values } = event.data;
        values
            .filter((value: AblyStatEventValue) => value.DataCenter.toLowerCase() === regionId.toLowerCase())
            .forEach((value: AblyStatEventValue) => {
                const { Name: name, DataCenter: dc } = value;

                if (!dc || dc.toLowerCase() !== regionId.toLowerCase()) {
                    return;
                }

                switch (name) {
                    case 'messages_per_second_by_data_center': {
                        handleMsgPerSecEvent(value);
                        break;
                    }
                    case 'channels_active_by_data_center': {
                        handleChannelsActiveEvent(value);
                        break;
                    }
                    case 'frontrouter_connections_active_by_data_center': {
                        handleFrontRouterConnectionsActiveEvent(value);
                        break;
                    }
                    default: {
                        // console.log('Unhandled event', name);
                        break;
                    }
                }
            });
    }

    const [channel] = useChannel('[?rewind=10]ably_stats', handleAblyStatsEvent);

    function loadHistory(): void {
        setAblyStats(defaultValue);
        channel.history((err, page) => {
            if (err) {
                console.error(err);
                return;
            }

            if (!page) {
                return;
            }

            if (page.items.length === 0) {
                return;
            }

            page.items.forEach((item) => {
                handleAblyStatsEvent(item);
            });
        });
    }

    useEffect(loadHistory, [regionId]);

    return {
        ...ablyStats,
        region: {
            id: regionId,
            name: DataCenters.find((dc) => dc.region_id.toLowerCase() === regionId.toLowerCase())?.label || '',
        },
    };
}

import React, { ReactElement } from 'react';
import useSession from '../hooks/use-session';
import EnsureValidSession from '../middleware/ensure-valid-session';
import SessionState from '../types/enum/session-state';
import Game from './game';
import Lobby from './lobby';
import MembersProvider from '../providers/members-provider';
import useUser from '../hooks/use-user';
import Login from './login';
import { UserStatus } from '../types/enum/user-status';
import SessionProvider from '../providers/session-provider';

function PageContent(): ReactElement {
    const { session } = useSession();
    const user = useUser();

    if (user.status === UserStatus.Joining) {
        return <Login />;
    }

    switch (session.state) {
        case SessionState.Lobby: {
            return <Lobby />;
        }
        case SessionState.Game: {
            return <Game />;
        }
        default: {
            return <div />;
        }
    }
}

export default function SessionPage(): ReactElement {
    return (
        <SessionProvider>
            <EnsureValidSession>
                <MembersProvider>
                    <PageContent />
                </MembersProvider>
            </EnsureValidSession>
        </SessionProvider>
    );
}

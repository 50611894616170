import React, { createContext, ReactElement, useEffect, useMemo, useState } from 'react';
import SessionState from '../types/enum/session-state';
import { Session } from '../types/session';
import { useChannel } from '@ably-labs/react-hooks';
import Channels from '../types/enum/channels';
import PathUtils from '../utils/path';
import { ChildrenProp } from '../types/props';

type SessionContextType = {
    session: Session;
    setSession(session: Session): void;
};

const defaultSession: Session = {
    id: '',
    moderator: {
        id: '',
        firstname: '',
        lastname: '',
    },
    state: SessionState.Initialized,
    createdAt: new Date().toISOString(),
};

export const SessionContext = createContext<SessionContextType>({
    session: defaultSession,
    setSession: () => {},
});

export default function SessionProvider(prop: ChildrenProp): ReactElement {
    const { children } = prop;

    const sessionId = PathUtils.getPathname();
    const [sessionState, setSessionState] = useState<Session>({
        ...defaultSession,
        id: sessionId,
    });

    const [channel] = useChannel(Channels.Sessions, sessionId, () => {});

    function loadSession(): void {
        if (sessionId === '') {
            return;
        }

        channel.history((err, page) => {
            if (err) {
                console.error(err);
                return;
            }

            if (!page) {
                console.info('No history found');
                return;
            }

            const items = page?.items;
            const foundSession = items?.find((item) => item.data.id === sessionId);

            if (!foundSession) {
                console.info('No session found');
                return;
            }

            setSessionState(foundSession.data);
        });
    }

    useEffect(loadSession, []);

    const value = useMemo(
        () => ({
            session: sessionState,
            setSession: setSessionState,
        }),
        [sessionState],
    );

    return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
}

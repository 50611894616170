import { IconProps } from '../../types/icons';
import React, { ReactElement } from 'react';

export default function ArrowLeft(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 12 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
        >
            <path
                d='M0.888131 11.2701L8.42772 18.8097C8.78321 19.153 9.25934 19.343 9.75355 19.3387C10.2478 19.3344 10.7205 19.1362 11.07 18.7867C11.4195 18.4373 11.6177 17.9645 11.622 17.4703C11.6263 16.9761 11.4363 16.4999 11.093 16.1445L4.886 9.93749L11.093 3.73053C11.4363 3.37503 11.6263 2.8989 11.622 2.40469C11.6177 1.91047 11.4195 1.43772 11.07 1.08824C10.7205 0.738768 10.2478 0.540535 9.75355 0.536242C9.25934 0.531946 8.78321 0.721934 8.42771 1.06528L0.888131 8.60487C0.534768 8.95834 0.336259 9.43768 0.336259 9.93749C0.336259 10.4373 0.534768 10.9166 0.888131 11.2701Z'
                fill='black'
            />
        </svg>
    );
}

import React, { createContext, ReactElement, useEffect, useMemo, useState } from 'react';
import useMembers from '../hooks/use-members';
import { GlobeSelection, UserRegionPoint } from '../types/globe';
import GlobeSelectionType from '../types/enum/globe-selection';

type GlobeDataContextType = {
    userRegions: UserRegionPoint[];
    selection: GlobeSelection;
    setSelection(selection: GlobeSelection): void;
};

const defaultSelection: GlobeSelection = {
    state: GlobeSelectionType.None,
};

export const GlobeDataContext = createContext<GlobeDataContextType>({
    userRegions: [],
    selection: defaultSelection,
    setSelection: () => {},
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function GlobeDataProvider(props: Props): ReactElement {
    const { children } = props;

    const [userRegions, setUserRegions] = useState<UserRegionPoint[]>([]);

    const users = useMembers();
    const [selection, setSelection] = useState<GlobeSelection>(defaultSelection);

    function updateUserRegion(): void {
        const regions: UserRegionPoint[] = [];
        users.forEach((user) => {
            const { location, id } = user;

            const targetRegion = regions.find(
                (v) => v.id === (location?.edgeLocation?.city || location?.region_id || ''),
            );
            if (targetRegion) {
                if (targetRegion.users.some((v) => v.id === id)) {
                    return;
                }

                targetRegion.users.push(user);
                const index = regions.findIndex((v) => v.id === targetRegion.id);
                regions[index] = targetRegion;
            } else {
                regions.push({
                    id: location?.edgeLocation?.city || location?.region_id || '',
                    lat: location?.edgeLocation?.latitude || location?.latitude || 0,
                    lng: location?.edgeLocation?.longitude || location?.longitude || 0,
                    name: location?.label || '',
                    users: [user],
                });
            }
        });

        setUserRegions(regions);
    }

    useEffect(updateUserRegion, [users]);

    const value = useMemo(
        () => ({
            userRegions,
            selection,
            setSelection,
        }),
        [userRegions, selection],
    );

    return <GlobeDataContext.Provider value={value}>{children}</GlobeDataContext.Provider>;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {MutableRefObject, ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Chart as ChartJS, LinearScale, LineController, LineElement, PointElement, Title} from 'chart.js';
import StreamingPlugin from '@robloche/chartjs-plugin-streaming';
import 'chartjs-adapter-luxon';
import {ChartJSOrUndefined} from 'react-chartjs-2/dist/types';
import LineChart from './line-chart';

ChartJS.register(StreamingPlugin, LineController, LineElement, PointElement, LinearScale, Title);

type Props = {
    id: string;
    values: number[];

    // Note: This reference is used to update the latest value in the StatisticsBlock component.
    // In this component we update the latest value based on the fake values.
    displayValue: MutableRefObject<number>;
};

const updateInterval = 14_000;

export default function RealtimeChart(props: Props): ReactElement {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, values, displayValue } = props;
    const ref = useRef<ChartJSOrUndefined<'line', (number | [number, number] | null)[], unknown>>(null);
    const [initialValues] = useState<{ x: number; y: number }[]>(
        values.map((num, i) => ({
            x: Date.now() - updateInterval * (values.length - i),
            y: num,
        })),
    );

    const valuesRef = useRef({
        newest: values.length > 0 ? values[values.length - 1] : 0,
        previous: values.length > 1 ? values[values.length - 2] : 0,
    });

    useEffect(() => {
        valuesRef.current = {
            newest: values.length > 0 ? values[values.length - 1] : 0,
            previous: values.length > 1 ? values[values.length - 2] : 0,
        };
    }, [values]);

    useEffect(() => {
        displayValue.current = values[values.length - 1];
    }, []);

    const onRefreshT = useCallback(
        (chart: any) => {
            function randomNumberBetween(min: number, max: number): number {
                if (min > max) {
                    return Math.floor(Math.random() * (min - max + 1) + max);
                }
                return Math.floor(Math.random() * (max - min + 1) + min);
            }

            function setNewMinMax(min: number, max: number): void {
                if (ref.current && ref.current.options.scales && ref.current.options.scales.y) {
                    ref.current.options.scales.y.max = max;
                    ref.current.options.scales.y.min = min;
                    ref.current.update();
                }
            }

            function getMinDataValue(history: number[]): number {
                return Math.min(...history) - 100;
            }

            function getMaxDataValue(history: number[]): number {
                return Math.max(...history) + 200;
            }

            chart.data.datasets.forEach((dataset: any) => {
                const point = randomNumberBetween(valuesRef.current.newest, valuesRef.current.previous);
                displayValue.current = point;

                dataset.data.push({
                    x: Date.now(),
                    y: point,
                });
                if (dataset.data.length > 0) {
                    setNewMinMax(
                        getMinDataValue(dataset.data.map((o: { y: number }) => o.y)),
                        getMaxDataValue(dataset.data.map((o: { y: number }) => o.y)),
                    );
                }
            });
        },
        [valuesRef, id],
    );

    const chart = useMemo(
        () => (
            <LineChart
                id={id}
                ref={ref}
                onRefresh={(c) => onRefreshT(c)}
                // @ts-ignore
                initial={initialValues}
            />
        ),
        [id, onRefreshT, initialValues],
    );

    return (
        <div className='relative h-full w-full overflow-hidden'>
            <div className='chart-container z-10 h-full w-full'>{chart}</div>
        </div>
    );
}

import React, { ReactElement } from 'react';
import useAblyStats from '../../../hooks/use-ably-stats';
import NumberUtils from '../../../utils/number';

export default function GlobeConnectionsContent(): ReactElement {
    const { globalStats } = useAblyStats();
    return (
        <p className='my-auto text-lg'>{NumberUtils.shorthand(globalStats.activeConnections.current, 2)} glob.cxn</p>
    );
}

import React, { ReactElement, useState } from 'react';
import StatisticsBlock from './statistics-block';
import StatisticsUptimeBlock from './statistics-uptime-block';
import useAblyStats from '../../hooks/use-ably-stats';
import useGlobeData from '../../hooks/use-globe-data';
import Class from '../../utils/classes';
import GlobeSelectionType from '../../types/enum/globe-selection';
import ArrowWithCircle from '../../components/icons/arrow-with-circle';
import useLocale from '../../hooks/use-locale';

export default function Statistics(): ReactElement {
    const [expanded, setExpanded] = useState(false);

    const { globalStats } = useAblyStats();
    const { selection } = useGlobeData();

    const { getText } = useLocale();

    return (
        <>
            <section
                className={Class.classNames(
                    'flex w-full flex-col rounded-xl bg-company-darkGrey p-4 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-5 md:space-y-0 md:bg-transparent md:p-0 md:py-5',
                    selection.state === GlobeSelectionType.None && expanded ? '' : 'hidden md:block',
                )}
            >
                <StatisticsBlock
                    id='globe-connection'
                    name='Connections'
                    values={globalStats.activeConnections.previousRecords}
                    digits={5}
                />
                <StatisticsBlock
                    id='globe-channel'
                    name='Channels'
                    values={globalStats.activeChannels.previousRecords}
                    digits={5}
                />
                <StatisticsBlock
                    id='globe-messages'
                    name='Messages'
                    values={globalStats.msgPerSec.previousRecords}
                    digits={5}
                    suffix='/s'
                />
                <StatisticsUptimeBlock />
                <div className='py-4 md:hidden'>
                    <button
                        type='button'
                        className='mx-auto flex w-fit items-center justify-center space-x-1'
                        onClick={() => setExpanded(!expanded)}
                    >
                        <span className='text-lg font-bold'>{getText('pages.landing.statistics.collapse')}</span>
                        <ArrowWithCircle className='h-5 w-5' />
                    </button>
                </div>
            </section>
            <section
                className={Class.classNames(
                    'absolute left-0 bottom-0 flex w-full px-4 md:hidden',
                    selection.state === GlobeSelectionType.None && !expanded ? '' : 'hidden md:block',
                )}
            >
                <div className='flex w-full rounded-t-xl bg-company-darkGrey p-4'>
                    <button
                        type='button'
                        className='mx-auto flex w-fit items-center justify-center space-x-1'
                        onClick={() => setExpanded(!expanded)}
                    >
                        <span className='text-lg font-bold'>{getText('pages.landing.statistics.expand')}</span>
                        <ArrowWithCircle className='h-5 w-5 rotate-180' />
                    </button>
                </div>
            </section>
        </>
    );
}

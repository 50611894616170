export const DataCenters = [
    {
        location: 'Ohio, US',
        region: 'North America',
        region_id: 'us-east-2-a',
        label: 'Ohio — US',
        latitude: 39.982_867,
        longitude: -83.130_909_4,
    },
    {
        location: 'North Virginia, US',
        region: 'North America',
        region_id: 'us-east-1-a',
        label: 'North Virginia — US',
        latitude: 38.852_134,
        longitude: -77.336_851,
    },
    {
        location: 'California, US',
        region: 'North America',
        region_id: 'us-west-1-a',
        label: 'California — US',
        latitude: 37.757_687,
        longitude: -122.455_109_3,
    },
    {
        location: 'Ireland, UK',
        region: 'Europe',
        region_id: 'eu-west-1-a',
        label: 'Ireland - Europe',
        latitude: 53.323_975_6,
        longitude: -6.532_752_5,
    },
    {
        location: 'Frankfurt, Germany',
        region: 'Europe',
        region_id: 'eu-central-1-a',
        label: 'Frankfurt - Europe',
        latitude: 50.121_190_9,
        longitude: 8.566_525,
    },
    {
        location: 'Sydney',
        region: 'Oceania',
        region_id: 'ap-southeast-2-a',
        label: 'Sydney — Oceania',
        latitude: -33.848_164_7,
        longitude: 150.791_893_9,
    },
    {
        location: 'Singapore',
        region: 'Asia',
        region_id: 'ap-southeast-1-a',
        label: 'Singapore — Asia',
        latitude: 1.313_996_1,
        longitude: 103.704_168_1,
    },
];

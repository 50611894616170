import React, { ReactElement } from 'react';
import LoadingSpinnerIcon from '../components/icons/loading-spinner';

export default function LoadingPage(): ReactElement {
    return (
        <div className='flex h-full w-full items-center'>
            <LoadingSpinnerIcon className='m-auto h-10 w-10 animate-spin' />
        </div>
    );
}

import React, { ReactElement } from 'react';
import Class from '../../../utils/classes';
import UserIcon from '../../icons/user';
import Sizes from '../../../constants/sizes';
import DynamicWordTypes from '../../../types/enum/dynamic-word-types';
import GlobeMsgPerSecContent from './globe-msg-per-sec-content';
import GlobeConnectionsContent from './globe-connections-content';
import GlobeChannelsContent from './globe-channels-content';
import MemberCountContent from './member-count-content';
import UserLatency from '../../users/user-latency';
import useUser from '../../../hooks/use-user';

type Props = {
    text: string;
    disabled?: boolean;
};

type ContentProps = {
    word: DynamicWordTypes;
};

function Content(props: ContentProps): ReactElement {
    const { word } = props;
    const user = useUser();

    switch (word) {
        case DynamicWordTypes.GlobeMsgPerSec: {
            return <GlobeMsgPerSecContent />;
        }
        case DynamicWordTypes.GlobeConnections: {
            return <GlobeConnectionsContent />;
        }
        case DynamicWordTypes.GlobeChannels: {
            return <GlobeChannelsContent />;
        }
        case DynamicWordTypes.ConnectedUsers: {
            return <MemberCountContent />;
        }
        case DynamicWordTypes.UserLatency: {
            return <UserLatency userId={user.id} className='my-auto text-lg' />;
        }
        default: {
            return <></>;
        }
    }
}

export default function DynamicWords(props: Props): ReactElement {
    const { disabled, text } = props;

    return (
        <div
            className={Class.classNames(
                'flex h-fit w-fit select-none whitespace-nowrap rounded-2xl bg-gradient-to-b px-2 py-1',
                'from-[#FB8E33] to-[#FDC851]',
                disabled ? 'opacity-0' : '',
            )}
        >
            <Content word={text as DynamicWordTypes} />
            <UserIcon className='my-auto fill-white' width={Sizes.icon.large} height={Sizes.icon.large} />
        </div>
    );
}

DynamicWords.defaultProps = {
    disabled: false,
};

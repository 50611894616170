import React, { ReactElement } from 'react';
import { IconProps } from '../../types/icons';

export default function VerticalMoreIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={width} height={height}>
            <rect x='0.3125' y='0.587769' width='23.1406' height='1.8595' fill='#C3C7CC' />
            <rect x='0.3125' y='7.33716' width='23.1406' height='1.8595' fill='#C3C7CC' />
            <rect x='0.3125' y='14.0867' width='23.1406' height='1.8595' fill='#C3C7CC' />
        </svg>
    );
}

// Use to get the pathname of the current page
function getPathname(): string {
    return window.location.pathname.replace('/', '');
}

const PathUtils = {
    getPathname,
};

export default PathUtils;

import { useContext } from 'react';
import { GlobeDataContext } from '../providers/globe-data-provider';
import { GlobeSelection, UserRegionPoint } from '../types/globe';
import GlobeSelectionType from '../types/enum/globe-selection';

type GlobeDataApi = {
    userRegions: UserRegionPoint[];
    selection: GlobeSelection;
    setSelection(selection: GlobeSelection): void;
    clearSelection(): void;
};

export default function useGlobeData(): GlobeDataApi {
    const globeData = useContext(GlobeDataContext);

    function handleClearSelectUser(): void {
        globeData.setSelection({
            state: GlobeSelectionType.None,
        });
    }

    return {
        userRegions: globeData.userRegions,
        selection: globeData.selection,
        setSelection: globeData.setSelection,
        clearSelection: handleClearSelectUser,
    };
}

function distance(lat1: number, lon1: number, lat2: number, lon2: number, unit: string): number {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
        dist *= 1.609_344;
    }
    if (unit === 'N') {
        dist *= 0.8684;
    }
    return dist;
}

const GlobeUtil = {
    distance,
};

export default GlobeUtil;

import React, { createContext, ReactElement, useMemo } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type NotificationContextType = {
    showError(message: string): void;
    showSuccess(message: string): void;
};

export const NotificationContext = createContext<NotificationContextType>({
    showError: () => {},
    showSuccess: () => {},
});

type Props = {
    children: ReactElement;
};

export default function NotificationProvider(props: Props): ReactElement {
    const { children } = props;

    function onShowError(message: string): void {
        toast.error(message);
    }

    function onShowSuccess(message: string): void {
        toast.success(message);
    }

    const values = useMemo(
        () => ({
            showError: onShowError,
            showSuccess: onShowSuccess,
        }),
        [onShowError, onShowSuccess],
    );

    return (
        <NotificationContext.Provider value={values}>
            {children}
            <ToastContainer
                position='top-center'
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
                theme='colored'
                draggable={false}
            />
        </NotificationContext.Provider>
    );
}

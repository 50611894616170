import os from './environment-variables';
import { EdgeLocations } from '../constants/edge-locations';
import { EdgeLocation } from '../types/globe';

async function getEdgeLocation(): Promise<EdgeLocation | undefined> {
    const result = await fetch(`${os.getEnv('REACT_APP_ABLY_GEOIP_API')}/network/edge-pop-detection`);
    const cfPop = result.headers.get('x-amz-cf-pop');
    if (!cfPop) {
        return undefined;
    }

    const targetPop = cfPop.slice(0, 3);
    // @ts-ignore
    const edgeLocation = EdgeLocations[targetPop];

    if (!edgeLocation) {
        return undefined;
    }

    return edgeLocation as EdgeLocation;
}

const GeoUtils = {
    getEdgeLocation,
};

export default GeoUtils;

import React, { ReactElement } from 'react';
import NotificationProvider from './providers/notification-provider';
import Router from './router';
import AblyProvider from './providers/ably-provider';
import UserProvider from './providers/user-provider';

function App(): ReactElement {
    console.info('Visit https://ably.com/ for more information.');

    return (
            <NotificationProvider>
                <AblyProvider>
                    <UserProvider>
                        <Router />
                    </UserProvider>
                </AblyProvider>
            </NotificationProvider>
    );
}

export default App;

import React, { forwardRef, ReactElement } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { Line } from 'react-chartjs-2';
import { DefaultLineChartConfig } from '../../constants/chart-config';
import { ChartData } from 'chart.js';

type Props = {
    id: string;
    initial?: number[] | { x: number; y: number };
    onRefresh: (chart: any) => void;
};

const LineChart = forwardRef<
    ChartJSOrUndefined<'line', (number | [number, number] | null)[], unknown> | undefined,
    Props
>((props: Props, ref): ReactElement => {
    const { id, initial, onRefresh } = props;

    const data: ChartData<'line', (number | [number, number] | null)[], unknown> = {
        datasets: [
            {
                label: id,
                backgroundColor: '#32C361',
                borderColor: '#32C361',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                borderWidth: 2,
                // @ts-ignore
                data: initial || [],
            },
        ],
    };

    const options: any = {
        ...DefaultLineChartConfig,
        scales: {
            ...DefaultLineChartConfig.scales,
            y: {
                ...DefaultLineChartConfig.scales.y,
            },
            x: {
                ...DefaultLineChartConfig.scales.x,
                realtime: {
                    ...DefaultLineChartConfig.scales.x.realtime,
                    onRefresh: (chart: any) => onRefresh(chart),
                },
            },
        },
    };

    return <Line ref={ref} data={data} options={options} />;
});

LineChart.defaultProps = {
    initial: [],
};

export default LineChart;

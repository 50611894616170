enum GlobeSelectionType {
    None = 'none',
    QRCode = 'qrcode',
    User = 'user',
    Region = 'region',
    UserRegion = 'user-region',
    UserInRegion = 'user-in-region',
}

export default GlobeSelectionType;

import { useContext } from 'react';
import { AblyContext } from '../providers/ably-provider';
import { ConnectionDetails } from '../types/ably';

type AblyApi = {
    getConnectionDetails(): Promise<ConnectionDetails>;
};

export default function useAbly(): AblyApi {
    const { channels } = useContext(AblyContext);

    async function getConnectionDetails(): Promise<ConnectionDetails> {
        const channel = channels.get('sessions');
        const { connectionDetails } = channel.connectionManager;

        if (!connectionDetails) {
            console.error('No connection details found');
            return {
                clientId: 'unknown',
                userDataCenter: 'unknown',
            };
        }

        const { clientId, serverId } = connectionDetails;
        const userDataCenter = serverId ? serverId.split('.')[3] : 'unknown';

        return {
            clientId,
            userDataCenter,
        };
    }

    return {
        getConnectionDetails,
    };
}

import React, { ReactElement } from 'react';
import CloseIcon from '../../components/icons/close';
import Sizes from '../../constants/sizes';
import useGlobeData from '../../hooks/use-globe-data';
import { Transition } from '@headlessui/react';
import Class from '../../utils/classes';
import UserLatency from '../../components/users/user-latency';

export default function UserStatisticsCard(): ReactElement {
    const {
        selection: { user: selectedUser },
        clearSelection,
    } = useGlobeData();

    function handleClose(): void {
        clearSelection();
    }

    return (
        <Transition
            show={selectedUser !== undefined}
            enter='transition-opacity duration-75'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity duration-0'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
        >
            <section
                className={Class.classNames(
                    'z-50 mx-auto w-full max-w-xs space-y-5 rounded-xl bg-company-darkGrey p-5 shadow-xl md:max-w-sm',
                    selectedUser ? '' : 'hidden',
                )}
            >
                <div className='flex justify-between'>
                    <h1 className='text-lg font-semibold antialiased text-company-green'>
                        {`${selectedUser?.firstname} ${selectedUser?.lastname}`}
                    </h1>
                    <button type='button' className='pointer-events-auto' onClick={() => handleClose()}>
                        <CloseIcon className='fill-white' width={Sizes.icon.button} height={Sizes.icon.button} />
                    </button>
                </div>
                <ul className='text-company-white space-y-2 text-base'>
                    <li className='flex justify-between'>
                        <h2 className='text-company-middleGrey'>Latency:</h2>
                        <UserLatency userId={selectedUser?.id || ''} />
                    </li>
                    <li className='flex justify-between'>
                        <h2 className='text-company-middleGrey'>Data Center:</h2>
                        <p>{selectedUser?.location?.label}</p>
                    </li>
                    <li className='flex justify-between'>
                        <h2 className='text-company-middleGrey'>PoP</h2>
                        <p>{selectedUser?.location?.edgeLocation?.city}</p>
                    </li>
                    <li className='flex justify-between'>
                        <h2 className='text-company-middleGrey'>Device</h2>
                        <p>{selectedUser?.device}</p>
                    </li>
                </ul>
            </section>
        </Transition>
    );
}

import React, { ReactElement } from 'react';
import SelectedUserStatisticCard from './user-statistics-card';
import MemberCount from './member-count';
import SelectedRegionStatisticCard from './selected-region-statistic-card';
import Dashboard from './dashboard';

export default function GlobeUiContainer(): ReactElement {
    return (
        <div className='pointer-events-none absolute z-50 flex h-full w-full max-w-screen-xl select-none flex-col justify-between md:top-1/2 md:left-1/2 md:h-fit md:-translate-y-1/2 md:-translate-x-1/2 md:flex-row'>
            <div className='flex h-full w-full flex-col justify-end md:h-auto'>
                <div className='space-y-5'>
                    <SelectedUserStatisticCard />
                    <MemberCount />
                    <SelectedRegionStatisticCard />
                </div>
            </div>
            <Dashboard theme='dark' />
        </div>
    );
}

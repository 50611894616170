import { ClientRect } from '@dnd-kit/core/dist/types';

type Position = {
    x: number;
    y: number;
};

type Size = {
    width: number;
    height: number;
};

function MousePositionToCanvasCoordinates(rect: ClientRect, size: Size, mousePosition: Position): Position {
    return {
        x: mousePosition.x - rect.left - size.width / 2,
        y: mousePosition.y - rect.top - size.height / 2,
    };
}

const GameCanvasUtilities = {
    MousePositionToCanvasCoordinates,
};

export default GameCanvasUtilities;

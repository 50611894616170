/* eslint-disable max-len */
import React, {InputHTMLAttributes, ReactElement} from 'react';

type Props = InputHTMLAttributes<HTMLInputElement> & {
    error: string | undefined;
};

export default function TextInput(props: Props): ReactElement {
    const { error, ...rest } = props;

    return (
        <div>
            <input
                className='mt-2 block w-full border-b-2 border-white bg-company-darkGrey py-0.5 placeholder-white focus:outline-none'
                {...rest}
            />
            {error && <p className='absolute text-sm text-company-orange'>{error}</p>}
        </div>
    );
}

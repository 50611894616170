import React, { ReactElement } from 'react';
import Class from '../utils/classes';
import useSession from '../hooks/use-session';
import SessionState from '../types/enum/session-state';
import useLocale from '../hooks/use-locale';

type Theme = 'dark' | 'light';

type ToggleOptionProps = {
    active: boolean;
    theme: Theme;
    name: string;
    onClick(): void;
};

function ToggleOption(props: ToggleOptionProps): ReactElement {
    const { active, theme, name, onClick } = props;

    if (active) {
        return (
            <p
                className={Class.classNames(
                    'w-full rounded-3xl py-1 px-3 text-sm font-bold',
                    theme === 'dark' ? 'bg-company-darkGrey text-white' : 'bg-company-middleGrey text-white',
                )}
            >
                {name}
            </p>
        );
    }

    return (
        <button type='button' onClick={onClick}>
            <span
                className={Class.classNames(
                    'w-full rounded-3xl py-1 px-3 text-sm',
                    theme === 'dark' ? 'bg-black text-company-middleGrey' : 'bg-white text-black',
                )}
            >
                {name}
            </span>
        </button>
    );
}

type Props = {
    theme: Theme;
};

export default function StateToggle(props: Props): ReactElement {
    const { theme } = props;

    const { getText } = useLocale();

    const {
        updateState,
        session: { state },
    } = useSession();

    function handleStatsClick(newState: SessionState): void {
        updateState(newState);
    }

    return (
        <div
            className={Class.classNames(
                'pointer-events-auto flex w-fit items-center self-end rounded-3xl border-2 text-white',
                theme === 'dark' ? 'ml-auto border-company-darkGrey' : 'border-company-lightGrey',
            )}
        >
            <ToggleOption
                active={state === SessionState.Lobby}
                theme={theme}
                name={getText('components.stateToggle.lobby')}
                onClick={() => handleStatsClick(SessionState.Lobby)}
            />
            <ToggleOption
                active={state === SessionState.Game}
                theme={theme}
                name={getText('components.stateToggle.game')}
                onClick={() => handleStatsClick(SessionState.Game)}
            />
        </div>
    );
}

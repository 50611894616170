import React, { ReactElement } from 'react';
import useLocale from '../hooks/use-locale';
import Class from '../utils/classes';
import Chevron from './icons/chevron';
import QRCode from './qr-code';
import StateToggle from './state-toggle';

type Props = {
    sessionPath: string;
    theme: 'light' | 'dark';
};

export default function JoinSession(props: Props): ReactElement {
    const { sessionPath, theme } = props;
    const baseUrl = window.location.href.split('/')[2];

    const { getText } = useLocale();

    return (
        <section
            className={Class.classNames(
                'align-between flex h-fit w-full gap-x-16 rounded-xl p-4',
                theme === 'dark'
                    ? 'flex-row bg-company-darkGrey text-white'
                    : 'min-w-xs max-w-md flex-col-reverse space-y-7 bg-white  p-8 text-company-darkGrey shadow-section shadow-company-lightGrey',
            )}
        >
            {theme === 'light' && (
                <div className='order-1'>
                    <StateToggle theme='light' />
                </div>
            )}
            <div className='flex h-fit flex-col justify-between space-y-5'>
                <div>
                    <h2 className='font-bold'>{getText(`components.joinSession.title`)}</h2>
                    <p>{getText(`components.joinSession.copy`)}</p>
                </div>
                <a href={`${sessionPath}`} className='relative flex space-x-2'>
                    <div className='my-1.5'>
                        <Chevron className='fill-company-blue' width={14} height={12} />
                    </div>
                    <span className='h-fit font-bold text-company-blue'>
                        {baseUrl}
                        {sessionPath}
                        <span className='ml-2 inline-flex'>
                            <Chevron width={14} height={12} color={theme === 'dark' ? '#fff' : '#000'} />
                        </span>
                    </span>
                </a>
            </div>
            <div className='h-fit w-full max-w-[134px]'>
                <QRCode theme={theme} sessionPath={`${baseUrl}${sessionPath}`} />
            </div>
        </section>
    );
}

import { useEffect, useState } from 'react';
import { useChannel } from '@ably-labs/react-hooks';
import useUser from './use-user';

export default function useUserLatency(userId: string): number | 0 {
    const [latency, setLatency] = useState<number>(0);

    const user = useUser();

    const [channel] = useChannel('user-latency', userId, (message) => {
        const { timestamp } = message.data;
        const now = Date.now();

        setLatency(Math.max(now - timestamp, 0));
    });

    const [receiverChannel] = useChannel('user-latency', `${user.id}:request`, () => {
        channel.publish(user.id, { timestamp: Date.now() });
    });

    useEffect(() => {
        channel.publish(user.id, { timestamp: Date.now() });

        const interval = setInterval(() => {
            channel.publish(user.id, { timestamp: Date.now() });
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        receiverChannel.publish(`${userId}:request`, { timestamp: Date.now() });
    }, [userId]);

    return latency;
}

import { useContext } from 'react';
import { SessionContext } from '../providers/session-provider';
import humanId from 'human-id';
import SessionState from '../types/enum/session-state';
import { Session, SessionModerator } from '../types/session';
import { useChannel } from '@ably-labs/react-hooks';
import Channels from '../types/enum/channels';
import WordsTable from '../constants/test-data/words-table';
import { AblyContext } from '../providers/ably-provider';
import useUser from './use-user';

type SessionApi = {
    session: Session;
    isModerator: boolean;
    moderator: SessionModerator;
    createSession(firstname: string, lastname: string): void;
    updateState(state: SessionState): void;
};

export default function useSession(): SessionApi {
    const { session, setSession } = useContext(SessionContext);
    const ably = useContext(AblyContext);
    const { firstname, lastname } = useUser();

    const [channel] = useChannel(Channels.Sessions, session.id, (msg) => {
        setSession(msg.data);
    });

    // @ts-ignore
    const { clientId } = channel.connectionManager.options;

    async function initializeGameState(sessionId: string): Promise<void> {
        const gameState = await ably.channels.get(`${Channels.GameStates}:${sessionId}`);

        const gameStateHistory = await gameState.history();
        if (gameStateHistory.items.length === 0) {
            gameState.publish('game-sync', {
                gameCanvasWords: [],
                allWords: WordsTable.map((item) => `${item.id}#${item.text}`),
            });

            return;
        }

        const { allWords, gameCanvasWords } = gameStateHistory.items[0].data;
        gameState.publish('game-sync', {
            gameCanvasWords: gameCanvasWords || [],
            allWords: allWords || WordsTable.map((item) => `${item.id}#${item.text}`),
        });
    }

    async function handleCreateSession(first: string, last: string): Promise<void> {
        const id = humanId({
            separator: '-',
            capitalize: false,
            adjectiveCount: 0,
        });

        const moderator: SessionModerator = {
            id: clientId,
            firstname: first,
            lastname: last,
        };

        const value: Session = {
            id,
            moderator,
            state: SessionState.Lobby,
            createdAt: new Date().toISOString(),
        };

        setSession(value);

        await initializeGameState(id);
        await channel.publish(id, value);

        window.location.pathname = id;
    }

    async function handleUpdateState(state: SessionState): Promise<void> {
        const value: Session = {
            ...session,
            state,
        };

        setSession(value);
        await channel.publish(session.id, value);

        if (state === SessionState.Game) {
            await initializeGameState(session.id);
        }
    }

    return {
        session,
        moderator: session.moderator,
        isModerator: `${firstname}#${lastname}` === `${session.moderator.firstname}#${session.moderator.lastname}`,
        createSession: handleCreateSession,
        updateState: handleUpdateState,
    };
}

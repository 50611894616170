import { useEffect, useState } from 'react';

export default function useMousePosition(): { x: number; y: number } {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const updateMousePosition = (e: MouseEvent): void => {
            setMousePosition({ x: e.clientX, y: e.clientY });
        };

        const updateTouchPosition = (e: TouchEvent): void => {
            setMousePosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        };

        window.addEventListener('mousemove', updateMousePosition);
        // Mobile support
        window.addEventListener('touchmove', updateTouchPosition);

        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);

    return mousePosition;
}

import React, { ReactElement } from 'react';
import useMembers from '../../../hooks/use-members';

export default function MemberCountContent(): ReactElement {
    const members = useMembers();
    return (
        <p className='my-auto text-lg'>
            {members.length} user{members.length > 1 ? 's' : ''}
        </p>
    );
}

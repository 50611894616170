import React, { ReactElement } from 'react';
import UserAvatar from '../../components/users/user-avatar';
import Class from '../../utils/classes';
import UserAvatars from '../../components/users/user-avatars';
import { UserStatus } from '../../types/enum/user-status';
import useMembers from '../../hooks/use-members';

export default function AvatarStack(): ReactElement {
    const allUsers = useMembers();
    const users = allUsers.filter((user) => user.status === UserStatus.Online);

    const userSliceLimit = 4;

    return (
        <section className='z-50 flex items-center'>
            {users.length < userSliceLimit ? (
                users.map((user, index) => (
                    <UserAvatar
                        key={user.id}
                        shortName={`${user.firstname[0]}${user.lastname[0]}`.toUpperCase()}
                        fullname={`${user.firstname} ${user.lastname}`}
                        className={Class.classNames(index === 0 ? '' : '-ml-2')}
                    />
                ))
            ) : (
                <>
                    {users.slice(0, userSliceLimit - 1).map((user, index) => (
                        <UserAvatar
                            key={user.id}
                            shortName={`${user.firstname[0]}${user.lastname[0]}`.toUpperCase()}
                            fullname={`${user.firstname} ${user.lastname}`}
                            className={Class.classNames(index === 0 ? '' : '-ml-2')}
                        />
                    ))}
                    <UserAvatars users={users.slice(userSliceLimit - 1, users.length)} className='-ml-2' />
                </>
            )}
        </section>
    );
}

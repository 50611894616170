import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Globe from 'react-globe.gl';
import { useWindowSize } from 'usehooks-ts';
import useGlobeData from '../../hooks/use-globe-data';
import { GlobeInteractPoint, UserRegionPoint } from '../../types/globe';
import { User } from '../../types/user';
import GlobeSelectionType from '../../types/enum/globe-selection';
import testArcs from '../../constants/arcs';
import { DataCenters } from '../../constants/data-centers';
import GlobeUtil from '../../utils/globe';
import { EdgeLocations } from '../../constants/edge-locations';
import GlobeDataCenter from '../../components/globe/global-data-center';
import { UserStatus } from '../../types/enum/user-status';
import GlobeUserMarker from '../../components/globe/globe-user-marker';
import GlobeUsersMarker from '../../components/globe/globe-users-marker';
import GlobeInteractPointType from '../../types/enum/globe-interact-point-types';

export default function GlobeSection(): ReactElement {
    const globe = useGlobeData();

    const [locked, setLocked] = useState(false);
    const [displayMoreUsersInRegion, setDisplayMoreUsersInRegion] = useState(false);

    const [interactPoints, setInteractPoints] = useState<GlobeInteractPoint[]>([]);

    const windowSize = useWindowSize();
    const globeEl = useRef();

    let shiftAmount = windowSize.width / 2 - windowSize.width * 0.75;
    if (windowSize.width < 1024) {
        shiftAmount = 0;
    }

    function handleUserClick(event: UserRegionPoint): void {
        const userCenterCoords = {
            lat: event.lat,
            lng: event.lng,
            altitude: 2,
        };

        if (locked) {
            return;
        }

        setLocked(true);

        // @ts-ignore
        globeEl.current.pointOfView(userCenterCoords, 1000);
        if (event.users.length > 1) {
            globe.setSelection({
                regionId: event.id,
                state: GlobeSelectionType.UserRegion,
            });
        } else {
            globe.setSelection({
                state: GlobeSelectionType.User,
                user: event.users[0],
            });
        }

        setTimeout(() => {
            setLocked(false);
        }, 1500);
    }

    function selectUsersInRegion(event: UserRegionPoint): void {
        const userCenterCoords = {
            lat: event.lat,
            lng: event.lng,
            altitude: 2,
        };

        if (locked) {
            return;
        }

        setLocked(true);

        // @ts-ignore
        globeEl.current.pointOfView(userCenterCoords, 1000);
        globe.setSelection({
            regionId: event.id,
            state: GlobeSelectionType.UserRegion,
        });

        setTimeout(() => {
            setLocked(false);
        }, 1500);
    }

    function selectUserInRegion(user: User): void {
        globe.setSelection({
            ...globe.selection,
            state: GlobeSelectionType.UserInRegion,
            user,
        });
        setDisplayMoreUsersInRegion(false);
    }

    function selectRegion(event: any): void {
        const userCenterCoords = {
            lat: event.lat,
            lng: event.lng,
            altitude: 2,
        };

        setLocked(true);

        // @ts-ignore
        globeEl.current.pointOfView(userCenterCoords, 1000);
        globe.setSelection({
            regionId: event.id,
            state: GlobeSelectionType.Region,
        });

        setTimeout(() => {
            setLocked(false);
        }, 1500);
    }

    function handleZoom(coords: any): void {
        if (locked) {
            return;
        }

        if (globe.selection.regionId) {
            setDisplayMoreUsersInRegion(false);
        }

        if (globe.selection.state !== GlobeSelectionType.None) {
            // @ts-ignore
            globeEl.current.pointOfView(
                {
                    ...coords,
                    // altitude: 2,
                },
                500,
            );
        }
    }

    function closeRegion(): void {
        setDisplayMoreUsersInRegion(false);
        globe.clearSelection();
    }

    function showMoreUsersInRegion(): void {
        if (displayMoreUsersInRegion) {
            return;
        }

        setDisplayMoreUsersInRegion(true);
        globe.setSelection({
            regionId: globe.selection.regionId,
            state: GlobeSelectionType.UserRegion,
        });
    }

    useEffect(() => {
        // @ts-ignore
        globeEl.current.controls().enableZoom = windowSize.width < 1024;
    }, [windowSize]);

    useEffect(() => {
        const users =
            globe.selection.state === GlobeSelectionType.UserRegion ||
            globe.selection.state === GlobeSelectionType.UserInRegion
                ? globe.userRegions.filter((r) => r.id === globe.selection.regionId)
                : globe.userRegions;

        const dcs = DataCenters.map((d) => ({
            type: GlobeInteractPointType.DataCenter,
            data: {
                id: d.region_id,
                name: d.label,
                lng: d.longitude,
                lat: d.latitude,
            },
        }));

        const data: GlobeInteractPoint[] = users
            .map((u) => ({
                type: GlobeInteractPointType.User,
                data: u,
            }))
            // @ts-ignore
            .concat(dcs);

        setInteractPoints(data);
    }, [globe.userRegions, globe.selection]);

    return (
        <section
            style={{
                marginLeft: `-${shiftAmount}px`,
                marginTop: `-${shiftAmount}px`,
            }}
            className='select-none'
        >
            <Globe
                ref={globeEl}
                width={windowSize.width + shiftAmount}
                height={windowSize.height}
                backgroundColor='#000'
                globeImageUrl='assets/globe-texture-earth-night.jpeg'
                onZoom={(e) => handleZoom(e)}
                arcsData={[
                    ...testArcs,
                    ...Object.keys(EdgeLocations)
                        // @ts-ignore
                        .map((key) => EdgeLocations[key])
                        .map((d) => {
                            const dataCenterDistance = DataCenters.map((dc) => ({
                                distance: GlobeUtil.distance(dc.latitude, dc.longitude, d.latitude, d.longitude, 'K'),
                                dc,
                            }));

                            const closestDataCenter = dataCenterDistance.sort((a, b) => a.distance - b.distance)[0];

                            return {
                                startLat: d.latitude,
                                startLng: d.longitude,
                                endLat: closestDataCenter?.dc.latitude || 0,
                                endLng: closestDataCenter.dc.longitude || 0,
                                color: 'rgba(255,255,255,0.75)',
                            };
                        }),
                ]}
                arcColor={(e: any) => e.color || '#32C361'}
                arcDashGap={0.5}
                arcsTransitionDuration={0}
                arcDashAnimateTime={() => Math.random() * 4000 + 500}
                pointsData={Object.keys(EdgeLocations)
                    // @ts-ignore
                    .map((key) => EdgeLocations[key])}
                pointLabel='city'
                pointLat='latitude'
                pointLng='longitude'
                pointAltitude={0}
                pointColor={() => '#fff'}
                pointRadius={0.25}
                ringsData={DataCenters}
                enablePointerInteraction={false}
                ringLat='latitude'
                ringLng='longitude'
                ringRepeatPeriod={600}
                ringMaxRadius={0.5}
                htmlElementsData={interactPoints.map((v) => ({
                    type: v.type,
                    ...v.data,
                }))}
                // @ts-ignore
                // eslint-disable-next-line react/no-unstable-nested-components
                htmlElement={(v: any) => {
                    switch (v.type) {
                        case GlobeInteractPointType.DataCenter: {
                            return GlobeDataCenter(v, selectRegion);
                        }
                        case GlobeInteractPointType.User: {
                            return v.users.filter((item: any) => item.status === UserStatus.Online).length === 1
                                ? GlobeUserMarker(handleUserClick, v, globe.selection.user)
                                : GlobeUsersMarker(
                                      selectUsersInRegion,
                                      selectUserInRegion,
                                      closeRegion,
                                      showMoreUsersInRegion,
                                      v,
                                      globe.selection,
                                      displayMoreUsersInRegion,
                                  );
                        }
                        default: {
                            return <></>;
                        }
                    }
                }}
            />
        </section>
    );
}

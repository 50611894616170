import { Transition } from '@headlessui/react';
import React, { ReactElement } from 'react';
import Class from '../../utils/classes';

type Props = {
    name: string;
    className?: string;
};

export default function UserMarkBadge(props: Props): ReactElement {
    const { name, className } = props;

    return (
        <Transition
            appear
            show={!!name}
            enter='ease-out duration-200'
            enterFrom='opacity-0 scale-10'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
        >
            <div
                className={Class.classNames(
                    ' w-fit rounded-full border-4 border-white bg-gradient-to-b from-[#D9D9D9] to-[#F4F4F4] p-1 text-sm font-semibold antialiased text-black',
                    className || '',
                )}
            >
                {name}
            </div>
        </Transition>
    );
}

UserMarkBadge.defaultProps = {
    className: '',
};

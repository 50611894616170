import React, { ReactElement } from 'react';
import JoinSessionForm from '../forms/join-session';
import useUser from '../hooks/use-user';
import WelcomeText from '../sections/login/welcome-text';
import Logo from '../sections/lobby/logo';
import WelcomeGlobe from '../sections/login/welcome-globe';

export default function Login(): ReactElement {
    const user = useUser();

    function handleSubmit(firstname: string, lastname: string): void {
        user.setName(firstname, lastname);
    }

    return (
        <div className='h-screen w-screen'>
            <div className='absolute top-0 left-0 h-screen w-screen'>
                <WelcomeGlobe />
            </div>
            <div className='my-auto flex h-full flex-col items-center justify-center md:flex-row'>
                <div className='z-20 mx-8 flex h-fit flex-col space-y-5 md:my-auto md:mx-0 md:w-full md:max-w-sm'>
                    <Logo className='mx-auto md:mx-0' />
                    <div className='z-20 h-fit md:my-auto md:mx-0 md:w-full md:max-w-sm md:-translate-y-0'>
                        <div className='w-max-w-xs text-md m-auto space-y-5 rounded-lg bg-company-darkGrey p-10 md:max-w-sm'>
                            <WelcomeText />
                            <JoinSessionForm onSubmit={(firstname, lastname) => handleSubmit(firstname, lastname)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

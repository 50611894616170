import { useChannel } from '@ably-labs/react-hooks';
import { Types } from 'ably';
import { useEffect, useState } from 'react';
import { AblyStatEventValue } from '../types/ably';
import { asyncInvoke } from '../utils/function';

type AblyStats = {
    globalStats: {
        msgPerSec: {
            current: number;
            previousRecords: number[];
        };
        activeChannels: {
            current: number;
            previousRecords: number[];
        };
        // front router connections
        activeConnections: {
            current: number;
            previousRecords: number[];
        };
    };
};

const maxHistoryLength = 10;

const defaultValue: AblyStats = {
    globalStats: {
        msgPerSec: {
            current: 0,
            previousRecords: [],
        },
        activeChannels: {
            current: 0,
            previousRecords: [],
        },
        activeConnections: {
            current: 0,
            previousRecords: [],
        },
    },
};

export default function useAblyStats(): AblyStats {
    const [ablyStats, setAblyStats] = useState<AblyStats>(defaultValue);

    function handleMsgPerSecEvent(event: AblyStatEventValue): void {
        const { Value: value } = event;

        setAblyStats((prevState) => ({
            ...prevState,
            globalStats: {
                ...prevState.globalStats,
                msgPerSec: {
                    current: value,
                    previousRecords: [...prevState.globalStats.msgPerSec.previousRecords, value].slice(
                        -maxHistoryLength,
                    ),
                },
            },
        }));
    }

    function handleChannelsActiveEvent(event: AblyStatEventValue): void {
        const { Value: value } = event;

        setAblyStats((prevState) => ({
            ...prevState,
            globalStats: {
                ...prevState.globalStats,
                activeChannels: {
                    current: value,
                    previousRecords: [...prevState.globalStats.activeChannels.previousRecords, value].slice(
                        -maxHistoryLength,
                    ),
                },
            },
        }));
    }

    function handleFrontRouterConnectionsActiveEvent(event: AblyStatEventValue): void {
        const { Value: value } = event;

        setAblyStats((prevState) => ({
            ...prevState,
            globalStats: {
                ...prevState.globalStats,
                activeConnections: {
                    current: value,
                    previousRecords: [...prevState.globalStats.activeConnections.previousRecords, value].slice(
                        -maxHistoryLength,
                    ),
                },
            },
        }));
    }

    function handleAblyStatsEvent(event: Types.Message): void {
        const { Values: values } = event.data;
        values.forEach((value: AblyStatEventValue) => {
            switch (value.Name) {
                case 'messages_per_second': {
                    handleMsgPerSecEvent(value);
                    break;
                }
                case 'channels_active': {
                    handleChannelsActiveEvent(value);
                    break;
                }
                case 'frontrouter_connections_active': {
                    handleFrontRouterConnectionsActiveEvent(value);
                    break;
                }
                default: {
                    // console.log('Unhandled event', name);
                    break;
                }
            }
        });
    }

    const [channel] = useChannel('[?rewind=10]ably_stats', handleAblyStatsEvent);

    function loadHistory(): void {
        setAblyStats(defaultValue);
        channel.history((err, page) => {
            if (err) {
                console.error(err);
                return;
            }

            if (!page) {
                return;
            }

            if (page.items.length === 0) {
                return;
            }

            page.items.forEach((item) => {
                handleAblyStatsEvent(item);
            });
        });
    }

    useEffect(asyncInvoke(loadHistory), []);

    return ablyStats;
}

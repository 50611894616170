import React, { ReactElement } from 'react';
import { IconProps } from '../../types/icons';

export default function LoadingSpinnerIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43' width={width} height={height}>
            <circle cx='21.5' cy='21.5' r='17.5' stroke='#292831' strokeWidth='7' />
            <path d='M4 21C4 11.6112 12 4.5 21 4' stroke='url(#paint0_linear_191_2915)' strokeWidth='7' />
            <defs>
                <linearGradient id='paint0_linear_191_2915' x1='7.84896' y1='29.409' x2='22.7808' y2='21.6873'
                                gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FF5416' />
                    <stop offset='0.2535' stopColor='#FF5115' />
                    <stop offset='0.461' stopColor='#FF4712' />
                    <stop offset='0.6523' stopColor='#FF350E' />
                    <stop offset='0.8327' stopColor='#FF1E08' />
                    <stop offset='1' stopColor='#FF0000' />
                </linearGradient>
            </defs>
        </svg>
    );
}

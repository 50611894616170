import React, { ReactElement } from 'react';
import { IconProps } from '../../types/icons';

export default function UserIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={width} height={height}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.8288 14.4748L10.7392 5.75781H9.41441L4.87097 13.8232L5.8288 14.4748ZM15.718 14.4748L10.8076 5.75781H12.1324L14.4041 9.79055L16.6758 13.8232L15.718 14.4748ZM10.7734 10.8221L15.6496 14.5261L14.6544 15.2017L10.7734 12.2548L6.89234 15.2017L5.89721 14.5261L10.7734 10.8221Z'
                fill='white'
            />
        </svg>
    );
}

import React, { ReactElement } from 'react';
import Statistics from './statistics';
import JoinSession from '../../components/join-session';
import StateToggle from '../../components/state-toggle';
import useGlobeData from '../../hooks/use-globe-data';
import Class from '../../utils/classes';
import useSession from '../../hooks/use-session';
import Logo from './logo';

type Theme = 'dark' | 'light';

type Props = {
    theme: Theme;
};

export default function Dashboard(props: Props): ReactElement {
    const { theme } = props;
    const sessionPath = window.location.pathname;
    const {
        selection: { user: selectedUser },
    } = useGlobeData();

    const { isModerator } = useSession();

    return (
        <section
            className={Class.classNames(
                'pointer-events-auto z-50 m-5 md:m-0 md:h-fit md:w-full md:max-w-[470px]',
                selectedUser ? 'hidden md:block' : '',
            )}
        >
            {isModerator ? (
                <div className='hidden md:block md:space-y-5'>
                    <div className='flex items-center'>
                        <Logo className='w-20' />
                        <StateToggle theme='dark' />
                    </div>
                    <JoinSession sessionPath={sessionPath} theme={theme} />
                </div>
            ) : (
                <Logo className='hidden w-20 md:block' />
            )}
            <Statistics />
        </section>
    );
}

import { nanoid } from 'nanoid';
import { Word } from '../../types/word';

const basisWords = [
    'I',
    'my',
    'you',
    'your',
    'we',
    'us',
    'our',
    'it',
    'these',
    'the',
    'a',
    'of',
    'at',
    'in',
    'into',
    'on',
    'and',
    'to',
    'but',
    'though',
    'over',
    'with',
    'yet',
    'not',
    'no',
    'if',
    '&',
    'than',
    'upon',
    'inside',
    'like',
    'much',
    'many',
];
const wordEndings = ['ed', 's', 'ly', 'ing', 'less', 'est'];
const verbs = [
    'bite',
    'can',
    'cry',
    'detect',
    'drink',
    'drive',
    'drool',
    'eat',
    'fail',
    'fiddle',
    'is',
    'kill',
    'let',
    'lie',
    'love',
    'measure',
    'must',
    'need',
    'play',
    'put',
    'scream',
    'spray',
    'squee',
    'want',
    'whisper',
];
const adjectives = [
    'abrupt',
    'dead',
    'delicious',
    'enormous',
    'evil',
    'fluffy',
    'fresh',
    'gentle',
    'hot',
    'lazy',
    'luscious',
    'magical',
    'perfect',
    'purple',
    'terminally',
    'toxic',
    'warm',
];

const nouns = [
    'abyss',
    'belly',
    'blood',
    'chocolate',
    'code',
    'crush',
    'death',
    'emergency',
    'expert',
    'failure',
    'finger',
    'ghost',
    'head',
    'kitten',
    'milk',
    'mirror',
    'monkey',
    'nachos',
    'pain',
    'ship',
    'strip',
    'sunshine',
    'sweat',
    'time',
    'urge',
];

const random = ['fridays', 'karaoke', 'nachos', 'splat', 'sploosh', 'squish', 'teeny'];

const nerdy = [
    '1337',
    'access',
    'action',
    'avatar',
    'bot',
    'byte',
    'catch',
    'cloud',
    'commodore64',
    'critical',
    'digital',
    'droid',
    'fire',
    'giga',
    'hack',
    'halt',
    'home',
    'interface',
    'light',
    'micro',
    'off',
    'online',
    'smart',
    'sudo',
    'system',
    'tech',
    'upgrade',
    'virus',
    'ware',
];

const keywords = [
    'Ably',
    'activate',
    'allocate',
    'availability',
    'bandwidth',
    'capacity',
    'center',
    'certainty',
    'cost',
    'data',
    'fault',
    'integrity',
    'kubernetes',
    'latency',
    'limit',
    'live',
    'message',
    'million',
    'perform',
    'performance',
    'pop',
    'protocol',
    'pubsub',
    'python',
    'realtime',
    'reliability',
    'roundtrip',
    'second',
];

const words: Word[] = [];

function addWordCategoryToArray(wordCategory: Array<string>): void {
    wordCategory.forEach((category) => {
        words.push({
            id: nanoid(4),
            text: `${category}`,
            position: {
                x: 0,
                y: 0,
            },
        });
    });
}

addWordCategoryToArray(basisWords);
addWordCategoryToArray(wordEndings);
addWordCategoryToArray(verbs);
addWordCategoryToArray(adjectives);
addWordCategoryToArray(nouns);
addWordCategoryToArray(random);
addWordCategoryToArray(nerdy);
addWordCategoryToArray(keywords);

const shuffledWords = words.sort(() => Math.random() - 0.5);

export default shuffledWords;

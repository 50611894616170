import { WordSelection } from '../types/word';
import useSession from './use-session';
import { useChannel } from '@ably-labs/react-hooks';
import { SelectionType } from '../types/enum/selection-type';
import { useContext } from 'react';
import { WordMarkerContext } from '../providers/word-marker-provider';
import useUser from './use-user';

type WordMarkerApi = {
    selections: WordSelection[];
    selectWord(wordId: string): void;
    unselectWord(wordId: string): void;
};

export default function useWordMarker(): WordMarkerApi {
    const { selection } = useContext(WordMarkerContext);

    const {
        session: { id: sessionId },
    } = useSession();

    const user = useUser();

    const [channel] = useChannel('word-selection', sessionId, () => {});

    async function selectWord(wordId: string): Promise<void> {
        await channel.publish(sessionId, {
            type: SelectionType.Select,
            wordId,
            clientId: user.id,
            name: `${user.firstname[0]}${user.lastname[0]}`.toUpperCase(),
        });
    }

    async function unselectWord(wordId: string): Promise<void> {
        await channel.publish(sessionId, {
            type: SelectionType.Deselect,
            wordId,
            clientId: user.id,
        });
    }

    return {
        selections: selection,
        selectWord,
        unselectWord,
    };
}

import React, { ReactElement } from 'react';
import QRCode from 'react-qr-code';
import Class from '../utils/classes';

type Props = {
    sessionPath: string;
    theme: 'light' | 'dark';
};

export default function QRCodeViewer(props: Props): ReactElement {
    const { sessionPath, theme } = props;

    return (
        <div
            className={Class.classNames('w-full rounded border-4', theme === 'dark' ? 'border-white' : 'border-black')}
        >
            <QRCode
                className='h-auto w-full min-w-full'
                value={sessionPath}
                fgColor={theme === 'dark' ? '#000' : '#fff'}
                bgColor={theme === 'dark' ? '#fff' : '#000'}
            />
        </div>
    );
}

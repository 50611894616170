import React, { ReactElement, useEffect, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import useGameState from '../../hooks/use-game-state';
import WordbarWordItem from '../../components/game/wordbar/wordbar-word-item';
import AddWord from './add-word';
import DynamicWords from '../../components/game/words/dynamic-words';
import WordUtils from '../../utils/words';
import { Word } from '../../types/word';
import ArrowLeft from '../../components/icons/arrow-left';
import ArrowRight from '../../components/icons/arrow-right';

export default function Wordbar(): ReactElement {
    const { allWords, gameCanvasWords, markWordAsFocus } = useGameState();
    const { setNodeRef } = useDroppable({
        id: 'wordbar',
    });

    const [animate, setAnimate] = useState<string>('');
    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const [hideRightArrow, setHideRightArrow] = useState(false);
    const [wordRows, setWordRows] = useState<Word[][]>([[], [], []]);

    function focusWord(id: string): void {
        const wordbarWordItem = document.querySelector(`div[data-index="wordbar-${id}"`);
        if (wordbarWordItem) {
            wordbarWordItem.scrollIntoView({ behavior: 'smooth', inline: 'center' });
            setAnimate(id);
            markWordAsFocus(id);

            setTimeout(() => {
                setAnimate('');
            }, 2000);
        }
    }

    useEffect(() => {
        const chunkSize = 3;
        let chunkIndex = 0;
        const newWordRows: Word[][] = [[], [], []];

        allWords.forEach((v) => {
            newWordRows[chunkIndex].push(v);

            if (chunkIndex === chunkSize - 1) {
                chunkIndex = 0;
            } else {
                chunkIndex += 1;
            }
        });

        setWordRows(newWordRows);
    }, [allWords]);

    useEffect(() => {
        const word = allWords.find((v) => v.focus);
        if (word) {
            focusWord(word.id);
        }
    }, [wordRows]);

    useEffect(() => {
        const wordbar = document.querySelector('#scroll-container');
        if (!wordbar) {
            return;
        }

        function handleScroll(): void {
            if (!wordbar) {
                return;
            }

            setHideLeftArrow(wordbar.scrollLeft === 0);
            setHideRightArrow(wordbar.scrollLeft === wordbar.scrollWidth - wordbar.clientWidth);
        }

        wordbar.addEventListener('scroll', handleScroll);

        // eslint-disable-next-line consistent-return
        return () => {
            wordbar.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function renderWord(word: Word, index: number): ReactElement {
        const disabled = gameCanvasWords.some((gameCanvasWord) => gameCanvasWord.id === word.id);
        const dynamicWordPattern = WordUtils.includeDynamicWordPattern(word.text);

        return (
            <WordbarWordItem key={`wordbar-${word.id}`} id={`wordbar-${word.id}`} index={index} disabled={disabled}>
                {dynamicWordPattern ? (
                    <DynamicWords disabled={disabled} text={word.text} />
                ) : (
                    <p
                        className={`flex-n flex h-fit w-fit whitespace-nowrap rounded-full bg-gradient-to-b 
              ${word.id.slice(0, 2).includes('c$') ? 'from-[#C831BC] to-[#D5318E]' : 'from-[#24ADD8] to-[#3D8DED]'} 
              px-4 py-2 text-lg ${animate === word.id ? 'animate-pulse' : ''} ${disabled ? 'opacity-0' : ''}`}
                    >
                        {word.text}
                    </p>
                )}
            </WordbarWordItem>
        );
    }

    function scroll(type: 'forward' | 'backward'): void {
        const wordbar = document.querySelector('#scroll-container');
        if (!wordbar) {
            return;
        }

        wordbar.scrollTo({
            left: type === 'forward' ? wordbar.scrollLeft + 450 : wordbar.scrollLeft - 450,
            behavior: 'smooth',
        });
    }

    return (
        <div className='relative bg-black pb-5 md:pb-0'>
            <div
                className={`absolute top-1/2 z-10 ${
                    hideLeftArrow ? 'hidden' : 'hidden md:block'
                } -translate-y-1/2 -translate-x-1/2`}
            >
                <button
                    type='button'
                    onClick={() => scroll('backward')}
                    className='rounded-full bg-white p-4 drop-shadow-xl'
                >
                    <ArrowLeft className='h-5 w-5 fill-company-darkGrey' />
                </button>
            </div>
            <div
                className={`border-b-lg absolute h-3/4 w-5 bg-gradient-to-r from-zinc-900 to-transparent ${
                    hideLeftArrow ? 'hidden' : 'hidden md:block'
                }`}
            />
            <div
                id='scroll-container'
                ref={setNodeRef}
                className='border-b-lg flex h-1/4 w-full overflow-x-scroll p-5 pb-20 md:h-1/3'
            >
                <div className='space-y-5'>
                    {wordRows.map((wordRow, rowIndex) =>
                        wordRow.length === 0 ? (
                            <div className='mr-5 flex space-x-5'>
                                {Array.from(Array.from({ length: 10 }).keys()).map((_, index) => (
                                    <div
                                        className='flex h-fit w-fit animate-pulse select-none whitespace-nowrap rounded-2xl bg-company-middleGrey bg-opacity-50 bg-gradient-to-b px-2 py-1 text-transparent'
                                        key={`word-${index.toString()}`}
                                    >
                                        loading
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div key={`word-row-${rowIndex.toString()}`} className='mr-5 flex space-x-5'>
                                {wordRow.map((word, index) => renderWord(word, index))}
                            </div>
                        ),
                    )}
                </div>
            </div>
            <div className='absolute bottom-0 left-1/2 flex w-full -translate-y-10 -translate-x-1/2'>
                <AddWord />
            </div>
            <div
                className={`border-b-lg absolute right-0 top-0 h-3/4 w-5 bg-gradient-to-l from-zinc-900 to-transparent ${
                    hideRightArrow ? 'hidden' : 'hidden md:block'
                }`}
            />
            <div
                className={`absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2 ${
                    hideRightArrow ? 'hidden' : 'hidden md:block'
                }`}
            >
                <button
                    type='button'
                    onClick={() => scroll('forward')}
                    className='rounded-full bg-white p-4 drop-shadow-xl'
                >
                    <ArrowRight className='h-5 w-5 fill-company-darkGrey' />
                </button>
            </div>
            <div className='hidden h-5 w-full translate-y-1/2 rounded-b-xl bg-black shadow-xl md:block' />
        </div>
    );
}

import React, { createContext, ReactElement, useEffect, useMemo } from 'react';
import useSession from '../hooks/use-session';
import { usePresence } from '@ably-labs/react-hooks';
import { User } from '../types/user';
import useUser, { UserApi } from '../hooks/use-user';
import { UserStatus } from '../types/enum/user-status';
import usePrevious from '../hooks/use-previous';

type UserContextType = {
    users: User[];
};

export const UsersContext = createContext<UserContextType>({
    users: [],
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function MembersProvider(props: Props): ReactElement {
    const { children } = props;
    const { session } = useSession();

    const user = useUser();
    const prevUser: UserApi = usePrevious(user);

    const [data, update] = usePresence(`${session.id}:members`);

    useEffect(() => {
        // Dev-Note: If the user is the same, don't update, to avoid a infinite loop
        if (prevUser && user.id === prevUser.id && prevUser.firstname === user.firstname) {
            return;
        }

        if (user.status !== UserStatus.Online) {
            return;
        }

        update(user);
    }, [prevUser, user, update]);

    const value = useMemo(
        () => ({
            users: data.filter((item) => item.data).map((item) => item.data as User),
        }),
        [data],
    );

    return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
}

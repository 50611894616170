import React, { ReactElement } from 'react';
import useLocale from '../hooks/use-locale';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType, object, string } from 'yup';
import TextInput from '../components/input/text-input';
import SubmitButton from '../components/input/submit-button';

type Props = {
    onSubmit(firstname: string, lastname: string): void;
};

export default function SessionForm(props: Props): ReactElement {
    const { onSubmit } = props;
    const { getText } = useLocale();

    const schema = object({
        firstname: string()
            .min(1, getText('forms.createSession.fields.firstName.errors.minLength'))
            .max(20, getText('forms.createSession.fields.firstName.errors.maxLength'))
            .required(getText('forms.createSession.fields.firstName.errors.required')),
        lastname: string()
            .min(1, getText('forms.createSession.fields.lastName.errors.minLength'))
            .max(20, getText('forms.createSession.fields.lastName.errors.maxLength'))
            .required(getText('forms.createSession.fields.lastName.errors.required')),
    });

    const {
        control,
        handleSubmit: useFormHandleSubmit,
        formState: { errors },
    } = useForm<InferType<typeof schema>>({ resolver: yupResolver(schema) });

    function handleSubmit(values: InferType<typeof schema>): void {
        onSubmit(values.firstname, values.lastname);
    }

    return (
        <div className='w-max-w-xs text-md m-auto space-y-5 rounded-lg bg-company-darkGrey md:max-w-sm'>
            <div>
                <h1 className='font-bold'>{getText('pages.landing.sessionForm.title')}</h1>
                <p>{getText('pages.landing.sessionForm.copy')}</p>
            </div>
            <form className='space-y-7' onSubmit={useFormHandleSubmit(handleSubmit)}>
                <div className='space-y-5'>
                    <Controller
                        name='firstname'
                        control={control}
                        render={({ field }) => (
                            <TextInput
                                {...field}
                                name='firstname'
                                placeholder={getText(`forms.createSession.fields.firstName.label`)}
                                error={errors.firstname?.message}
                            />
                        )}
                    />
                    <Controller
                        name='lastname'
                        control={control}
                        render={({ field }) => (
                            <TextInput
                                {...field}
                                name='lastname'
                                placeholder={getText(`forms.createSession.fields.lastName.label`)}
                                error={errors.lastname?.message}
                            />
                        )}
                    />
                </div>
                <SubmitButton label={getText('pages.landing.sessionForm.button')} />
            </form>
        </div>
    );
}

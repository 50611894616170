import React, { ReactElement } from 'react';
import useUserLatency from '../../hooks/use-user-latency';

type Props = {
    userId: string;
    className?: string;
};

export default function UserLatency(props: Props): ReactElement {
    const { userId, className } = props;
    const latency = useUserLatency(userId);

    return <p className={className}>{latency} ms</p>;
}

UserLatency.defaultProps = {
    className: '',
};

import React, { createContext, ReactElement, useEffect, useMemo, useState } from 'react';
import { configureAbly } from '@ably-labs/react-hooks';
import os from '../utils/environment-variables';
import { nanoid } from 'nanoid';
import { asyncInvoke } from '../utils/function';
import { ChildrenProp } from '../types/props';
import LoadingPage from '../pages/loading';

export const AblyContext = createContext<any>(undefined);

export default function AblyProvider(props: ChildrenProp): ReactElement {
    const { children } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [ablyInstance, setAblyInstance] = useState<any>();

    async function initAbly(): Promise<void> {
        let instance;
        const clientId = nanoid();

        if (process.env.REACT_APP_ABLY_API_KEY) {
            if (os.getEnv('NODE_ENV', 'development') !== 'development') {
                console.error('Ably API key has been incorrectly configured. Please use token auth instead.');
            }
            instance = configureAbly({
                key: os.getEnv('REACT_APP_ABLY_API_KEY'),
                clientId,
                closeOnUnload: false,
            });
        } else {
            instance = configureAbly({
                authUrl: `/ably/token?clientId=${encodeURIComponent(clientId)}`,
            });
        }

        console.info('Ably instance created');
        setAblyInstance(instance);

        instance.connection.on('connected', () => {
            console.info('Ably connected');
            setIsLoading(false);
        });
    }

    useEffect(asyncInvoke(initAbly), []);

    const value = useMemo(() => ablyInstance, [ablyInstance, isLoading]);

    return <AblyContext.Provider value={value}>{isLoading ? <LoadingPage /> : children}</AblyContext.Provider>;
}

import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CreateSessionPage from './pages/create-session';
import NotFoundPage from './pages/not-found';
import SessionPage from './pages/session';
import RequestDemoSessionPage from './pages/request-demo-session';

export default function Router(): ReactElement {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<RequestDemoSessionPage />} />
                <Route path='/start' element={<CreateSessionPage />} />
                <Route path='404' element={<NotFoundPage />} />
                <Route path='*' element={<SessionPage />} />
            </Routes>
        </BrowserRouter>
    );
}

import React, { ReactElement } from 'react';
import CloseIcon from '../../components/icons/close';
import StateToggle from '../../components/state-toggle';
import Chevron from '../../components/icons/chevron';
import QRCodeViewer from '../../components/qr-code';
import useSession from '../../hooks/use-session';
import useLocale from '../../hooks/use-locale';

type Props = {
    onClose: () => void;
};

export default function GameJoinsSessionModal(props: Props): ReactElement {
    const { onClose } = props;

    const baseUrl = window.location.href.split('/')[2];
    const { session } = useSession();

    const { getText } = useLocale();

    return (
        <div className='absolute left-1/2 top-1/2 flex h-full w-full max-w-[900px] -translate-y-1/2 -translate-x-1/2 justify-center space-x-5 md:max-h-[667px]'>
            <div className='flex h-full w-full flex-col p-5 shadow-section md:w-full md:rounded-xl'>
                <div className='flex w-full justify-end'>
                    <button type='button' onClick={() => onClose()}>
                        <CloseIcon className='h-7 w-7 fill-[#C3C7CC]' />
                    </button>
                </div>
                <div className='mt-20 flex h-full w-full flex-col items-center md:mt-0 md:justify-center'>
                    <div className='h-fit w-full max-w-[300px] flex-col items-center space-y-5 md:my-auto'>
                        <div className='mx-auto w-fit'>
                            <StateToggle theme='light' />
                        </div>
                        <div className='flex h-fit flex-col items-center justify-center space-y-5 rounded-lg bg-company-lightGrey p-6 text-center text-black'>
                            <div>
                                <h2 className='font-bold'>{getText('pages.game.qrModal.title')}</h2>
                                <p className='text-base'>{getText('pages.game.qrModal.copy')}</p>
                            </div>
                            <span className='align-middle font-bold text-company-blue'>
                                <Chevron className='mr-2 inline-flex h-4 w-4 fill-company-blue' />
                                <span>
                                    {baseUrl}/{session.id}
                                </span>
                            </span>
                            <div className='w-1/2'>
                                <QRCodeViewer theme='dark' sessionPath={window.location.href} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import { Word, Status } from '../types/game-types';
import DynamicWordTypes from '../types/enum/dynamic-word-types';

export const getWordsByStatus = (words: Word[], status: Status): Word[] =>
    words.filter((word) => word.status === status);

function includeDynamicWordPattern(pattern: string): boolean {
    switch (pattern) {
        case DynamicWordTypes.GlobeMsgPerSec:
        case DynamicWordTypes.GlobeConnections:
        case DynamicWordTypes.GlobeChannels:
        case DynamicWordTypes.UserLatency:
        case DynamicWordTypes.ConnectedUsers: {
            return true;
        }
        default: {
            return false;
        }
    }
}

export const getWordById = (words: Word[], id: string): Word | undefined => words.find((word) => word.id === id);

const WordUtils = {
    includeDynamicWordPattern,
};

export default WordUtils;

import React, { ReactElement } from 'react';
import Class from '../../utils/classes';

type Props = {
    className?: string;
};

export default function EmptyGameCanvas(props: Props): ReactElement {
    const { className } = props;
    return (
        <div className={Class.classNames('text-center text-gray-300', className || '')}>
            <h3 className='text-xl font-bold'>Let’s collaborate!</h3>
            <p>Pull words from below into this area and create some latency free poetry.</p>
        </div>
    );
}

EmptyGameCanvas.defaultProps = {
    className: '',
};

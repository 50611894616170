const buttonIconSize = 25;
const iconSize = 20;
const lageIconSize = 30;
const smallIconSize = 15;

const Sizes = {
    icon: {
        default: iconSize,
        button: buttonIconSize,
        large: lageIconSize,
        small: smallIconSize,
    },
};

export default Sizes;

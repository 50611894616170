import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Class from '../../utils/classes';
import RealtimeChart from '../../components/charts/realtime-chart';
import NumberUtils from '../../utils/number';

type Props = {
    id: string;
    name: string;
    values: number[];
    digits?: number;
    suffix?: string;
    size?: 'small' | 'default';
};

export default function StatisticsBlock(props: Props): ReactElement {
    const { id, name, values, digits, suffix, size } = props;
    const latestValue = values[values.length - 1];

    const [lastFakeDigit, setFakeDigit] = useState(0);
    const hasOnlyZeros = values.every((num) => num === 0);

    const latestValueDisplayValue = useRef(latestValue);
    const isLoading = !latestValueDisplayValue.current && values.length === 0;

    useEffect(() => {
        setInterval(() => {
            const randomNum = Math.floor(Math.random() * 10);
            setFakeDigit(randomNum);
        }, 1000);
    }, []);

    function addFakeDigit(formatedValue: string, digit: number): string {
        return `${formatedValue.slice(0, -1)}${digit}${formatedValue.slice(-1)}`;
    }

    return (
        <div
            className={Class.classNames(
                'flex h-full rounded-xl bg-company-darkGrey',
                size === 'small' ? 'items-center md:flex-row md:p-0' : 'md:flex-col md:p-4',
            )}
        >
            <div className={Class.classNames('my-auto w-1/2 md:my-0', size === 'small' ? 'md:my-auto' : '')}>
                <p className={Class.classNames('text-base', size === 'small' ? 'md:text-lg' : '')}>{name}</p>
                <p
                    className={Class.classNames(
                        'flex items-end text-lg font-medium md:text-2xl',
                        size === 'small' ? 'md:text-xl' : '',
                    )}
                >
                    {addFakeDigit(NumberUtils.shorthand(latestValueDisplayValue.current, digits || 2), lastFakeDigit)}
                    {suffix && <span className='text-base font-thin text-company-middleGrey'>{suffix}</span>}
                </p>
            </div>
            <div
                className={Class.classNames(
                    'mx-auto h-16 w-1/2',
                    size === 'small' ? 'py-3 md:mx-auto md:h-16 md:w-1/2' : 'py-3 md:m-0 md:h-20 md:w-full',
                )}
            >
                {isLoading && (
                    <div className='h-12 w-full animate-pulse rounded-md bg-company-middleGrey bg-opacity-50 py-1' />
                )}
                {!hasOnlyZeros && <RealtimeChart id={id} values={values} displayValue={latestValueDisplayValue} />}
            </div>
        </div>
    );
}

StatisticsBlock.defaultProps = {
    suffix: undefined,
    digits: 2,
    size: 'default',
};

export const DefaultLineChartConfig: any = {
    animation: {
        easing: 'linear',
        duration: 1000,
    },
    maintainAspectRatio: false,

    scales: {
        y: {
            border: {
                display: false,
            },
            ticks: {
                display: false,
            },
            grid: {
                display: false,
            },
        },
        x: {
            border: {
                display: false,
            },
            ticks: {
                display: false,
            },
            grid: {
                display: false,
            },
            type: 'realtime',
            realtime: {
                duration: 112_000,
                refresh: 4000,
                delay: 6000,
            },
        },
    },
    interaction: {
        intersect: false,
    },
    layout: {
        autoPadding: false,
        padding: -10,
    },
};

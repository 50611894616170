function nFormatter(num: number, digits: number): string {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'm' },
        { value: 1e9, symbol: 'g' },
        { value: 1e12, symbol: 't' },
        { value: 1e15, symbol: 'p' },
        { value: 1e18, symbol: 'e' },
    ];
    const rx = /\.0+$|(\.\d*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(({ value }) => num >= value);
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}

const NumberUtils = {
    shorthand: nFormatter,
};

export default NumberUtils;

import React, { ReactElement, useEffect } from 'react';
import { useDroppable } from '@dnd-kit/core';
import useGameState from '../../hooks/use-game-state';
import GameCanvasWordItem from '../../components/game/game-canvas/game-canvas-word-item';
import Class from '../../utils/classes';
import EmptyGameCanvas from './empty-game-canvas';
import DynamicWords from '../../components/game/words/dynamic-words';
import WordUtils from '../../utils/words';

type Props = {
    selectedWordId: string | undefined;
};

export default function GameCanvas(props: Props): ReactElement {
    const { selectedWordId } = props;

    const { gameCanvasWords } = useGameState();

    const { setNodeRef } = useDroppable({
        id: 'game-canvas',
    });

    function centerGameCanvas(): void {
        const gameCanvasContainer = document.querySelector('#game-canvas-container');
        if (gameCanvasContainer) {
            gameCanvasContainer.scrollLeft = gameCanvasContainer.scrollWidth / 2 - gameCanvasContainer.clientWidth / 2;
        }
    }

    useEffect(() => {
        centerGameCanvas();
    }, []);

    return (
        <div
            id='game-canvas-container'
            className='relative h-[375px] w-[100%] max-w-screen-xl overflow-y-hidden overflow-x-scroll overscroll-x-none bg-white md:rounded-t-xl'
        >
            <div ref={setNodeRef} className='h-[375px] w-[900px]'>
                {gameCanvasWords.length === 0 && (
                    <EmptyGameCanvas className='mx-auto flex h-full max-w-sm flex-col justify-center' />
                )}
                {gameCanvasWords.map((word) => (
                    <GameCanvasWordItem
                        key={`k-game-canvas-${word.id}`}
                        id={`id-game-canvas-${word.id}`}
                        className={Class.classNames(
                            'h-10 w-fit select-none',
                            selectedWordId === word.id ? 'hidden' : '',
                        )}
                        position={word.position}
                    >
                        {WordUtils.includeDynamicWordPattern(word.text) ? (
                            <DynamicWords text={word.text} />
                        ) : (
                            <p
                                className={Class.classNames(
                                    'flex h-fit w-fit flex-nowrap whitespace-nowrap rounded-full bg-gradient-to-b from-[#24ADD8] to-[#3D8DED] px-4 py-2 text-lg',
                                    word.id.slice(0, 2).includes('c$')
                                        ? 'from-[#C831BC] to-[#D5318E]'
                                        : 'from-[#24ADD8] to-[#3D8DED]',
                                )}
                            >
                                {word.text}
                            </p>
                        )}
                    </GameCanvasWordItem>
                ))}
            </div>
        </div>
    );
}

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import useGlobeData from '../../hooks/use-globe-data';
import { GlobeInteractPoint } from '../../types/globe';
import { useWindowSize } from 'usehooks-ts';
import { DataCenters } from '../../constants/data-centers';
import GlobeInteractPointType from '../../types/enum/globe-interact-point-types';
import Globe from 'react-globe.gl';
import testArcs from '../../constants/arcs';
import { EdgeLocations } from '../../constants/edge-locations';
import GlobeUtil from '../../utils/globe';
import GlobeDataCenter from '../../components/globe/global-data-center';

export default function WelcomeGlobe(): ReactElement {
    const globe = useGlobeData();
    const [interactPoints, setInteractPoints] = useState<GlobeInteractPoint[]>([]);

    const windowSize = useWindowSize();
    const globeEl = useRef();

    useEffect(() => {
        // @ts-ignore
        globeEl.current.controls().enableZoom = windowSize.width < 1024;
        // roate the globe
        // @ts-ignore
        globeEl.current.controls().autoRotate = true;
    }, [windowSize]);

    useEffect(() => {
        const dcs = DataCenters.map((d) => ({
            type: GlobeInteractPointType.DataCenter,
            data: {
                id: d.region_id,
                name: d.label,
                lng: d.longitude,
                lat: d.latitude,
            },
        }));

        setInteractPoints(dcs);
    }, [globe.userRegions, globe.selection]);

    return (
        <section className='select-none'>
            <Globe
                ref={globeEl}
                width={windowSize.width}
                height={windowSize.height}
                backgroundColor='#000'
                globeImageUrl='assets/globe-texture-earth-night.jpeg'
                arcsData={[
                    ...testArcs,
                    ...Object.keys(EdgeLocations)
                        // @ts-ignore
                        .map((key) => EdgeLocations[key])
                        .map((d) => {
                            const dataCenterDistance = DataCenters.map((dc) => ({
                                distance: GlobeUtil.distance(dc.latitude, dc.longitude, d.latitude, d.longitude, 'K'),
                                dc,
                            }));

                            const closestDataCenter = dataCenterDistance.sort((a, b) => a.distance - b.distance)[0];

                            return {
                                startLat: d.latitude,
                                startLng: d.longitude,
                                endLat: closestDataCenter?.dc.latitude || 0,
                                endLng: closestDataCenter.dc.longitude || 0,
                                color: 'rgba(255,255,255,0.75)',
                            };
                        }),
                ]}
                arcColor={(e: any) => e.color || '#32C361'}
                arcDashGap={0.5}
                arcsTransitionDuration={0}
                arcDashAnimateTime={() => Math.random() * 4000 + 500}
                pointsData={Object.keys(EdgeLocations)
                    // @ts-ignore
                    .map((key) => EdgeLocations[key])}
                pointLabel='city'
                pointLat='latitude'
                pointLng='longitude'
                pointAltitude={0}
                pointColor={() => '#fff'}
                pointRadius={0.25}
                ringsData={DataCenters}
                enablePointerInteraction={false}
                ringLat='latitude'
                ringLng='longitude'
                ringRepeatPeriod={600}
                ringMaxRadius={0.5}
                htmlElementsData={interactPoints.map((v) => ({
                    type: v.type,
                    ...v.data,
                }))}
                // @ts-ignore
                // eslint-disable-next-line react/no-unstable-nested-components
                htmlElement={(v: any) => {
                    switch (v.type) {
                        case GlobeInteractPointType.DataCenter: {
                            return GlobeDataCenter(v, () => {});
                        }
                        default: {
                            return <div />;
                        }
                    }
                }}
            />
        </section>
    );
}

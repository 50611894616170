import React, { ReactElement } from 'react';
import AblyLogo from '../../assets/ably-logo.png';
import Class from '../../utils/classes';

type Props = {
    className?: string;
};

export default function Logo(props: Props): ReactElement {
    const { className } = props;

    return (
        <div className='z-30'>
            <img src={AblyLogo} alt='Ably Logo' className={Class.classNames('w-24', className || '')} />
        </div>
    );
}

Logo.defaultProps = {
    className: '',
};

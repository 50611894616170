/* eslint-disable max-len */
import React, { ReactElement } from 'react';

type Props = {
    label: string;
};

export default function SubmitButton(props: Props): ReactElement {
    const { label } = props;
    return (
        <button
            type='submit'
            className='w-full rounded-xl bg-company-orange px-4 py-2 text-center text-white transition-colors duration-300  hover:bg-company-lightOrange hover:text-white'
        >
            {label}
        </button>
    );
}

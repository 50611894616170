import React, { createContext, ReactElement, useEffect, useMemo, useState } from 'react';
import { User, UserMetadata } from '../types/user';
import { UserStatus } from '../types/enum/user-status';
import useAbly from '../hooks/use-ably';
import { asyncInvoke } from '../utils/function';
import { DataCenters } from '../constants/data-centers';
import UserAgentUtil from '../utils/user-agent';
import GeoUtils from '../utils/geo';

type UserContextType = {
    isLoading: boolean;
    user: User;
    setUser(user: User): void;
};

const defaultUser: User = {
    id: '',
    status: UserStatus.Joining,
    displayName: '',
    shortName: '',
    firstname: '',
    lastname: '',
    dataCenter: '',
    location: {
        region_id: '',
        label: '',
        location: '',
        latitude: 0,
        longitude: 0,
        region: '',
    },
    device: '',
};

export const UserContext = createContext<UserContextType>({
    isLoading: true,
    user: defaultUser,
    setUser: () => {},
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function UserProvider(props: Props): ReactElement {
    const { children } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(defaultUser);

    const ably = useAbly();

    async function loadUser(): Promise<void> {
        const data = localStorage.getItem('user-metadata');
        const userMetadata = data ? (JSON.parse(data) as UserMetadata) : undefined;

        const { clientId, userDataCenter } = await ably.getConnectionDetails();

        const dataCenter = DataCenters.find((dc) => dc.region_id.toLowerCase() === userDataCenter.toLowerCase());
        const userAgent = UserAgentUtil.parseUserAgent(navigator.userAgent);
        const edgeLocation = await GeoUtils.getEdgeLocation();

        const enchantUser: User = {
            id: clientId,
            status: userMetadata ? UserStatus.Online : UserStatus.Joining,
            firstname: userMetadata?.firstname || '',
            lastname: userMetadata?.lastname || '',
            displayName: `${userMetadata?.firstname || ''} ${userMetadata?.lastname || ''}`,
            shortName: `${userMetadata?.firstname?.charAt(0) || ''}${userMetadata?.lastname?.charAt(0) || ''}`,
            dataCenter: userDataCenter,
            location: dataCenter
                ? {
                      ...dataCenter,
                      edgeLocation,
                  }
                : undefined,
            device: userAgent.os,
        };

        setUser(enchantUser);

        setIsLoading(false);
    }

    useEffect(asyncInvoke(loadUser), []);

    const value = useMemo(
        () => ({
            isLoading,
            user,
            setUser,
        }),
        [isLoading, user],
    );

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

import React, { ReactElement } from 'react';
import useLocale from '../hooks/use-locale';
import { InferType, object, string } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import TextInput from '../components/input/text-input';
import SubmitButton from '../components/input/submit-button';

type Props = {
    title: string;
    copy: string;
};

export default function JoinSessionWithSessionId(props: Props): ReactElement {
    const { title, copy } = props;

    const { getText } = useLocale();

    const schema = object({
        sessionId: string().required(getText('forms.joinSessionById.fields.sessionId.error')),
    }).required();

    const {
        control,
        handleSubmit: useFormHandleSubmit,
        formState: { errors },
    } = useForm<InferType<typeof schema>>({ resolver: yupResolver(schema) });

    function handleSubmit(values: InferType<typeof schema>): void {
        window.location.href = `/${values.sessionId}`;
    }

    return (
        <form className='flex flex-col space-y-5' onSubmit={useFormHandleSubmit(handleSubmit)}>
            <div className='space-y-2'>
                <h1 className='text-2xl font-bold'>{title}</h1>
                <p className='text-base'>{copy}</p>
            </div>

            <Controller
                name='sessionId'
                control={control}
                render={({ field }) => (
                    <TextInput
                        type='text'
                        placeholder={getText('forms.joinSessionById.fields.sessionId.label')}
                        error={errors.sessionId?.message}
                        {...field}
                    />
                )}
            />

            <SubmitButton label={getText('forms.joinSessionById.submit')} />
        </form>
    );
}

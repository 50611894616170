import React, { ReactElement } from 'react';
import useLocale from '../hooks/use-locale';
import { InferType, object, string } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import TextInput from '../components/input/text-input';
import SubmitButton from '../components/input/submit-button';

type Props = {
    onSubmit(firstname: string, lastname: string): void;
};

export default function JoinSessionForm(props: Props): ReactElement {
    const { onSubmit } = props;

    const { getText } = useLocale();

    const schema = object({
        firstname: string()
            .min(1, getText('forms.joinSession.fields.firstName.errors.minLength'))
            .max(20, getText('forms.joinSession.fields.firstName.errors.maxLength'))
            .required(getText('forms.joinSession.fields.firstName.errors.required')),
        lastname: string()
            .min(1, getText('forms.joinSession.fields.lastName.errors.minLength'))
            .max(20, getText('forms.joinSession.fields.lastName.errors.maxLength'))
            .required(getText('forms.joinSession.fields.lastName.errors.required')),
    }).required();

    const {
        control,
        handleSubmit: useFormHandleSubmit,
        formState: { errors },
    } = useForm<InferType<typeof schema>>({ resolver: yupResolver(schema) });

    function handleSubmit(values: InferType<typeof schema>): void {
        onSubmit(values.firstname, values.lastname);
    }

    return (
        <form className='flex flex-col space-y-7' onSubmit={useFormHandleSubmit(handleSubmit)}>
            <div className='flex flex-col space-y-5'>
                <Controller
                    name='firstname'
                    control={control}
                    render={({ field }) => (
                        <TextInput
                            type='text'
                            placeholder={getText('forms.joinSession.fields.firstName.label')}
                            error={errors.firstname?.message}
                            {...field}
                        />
                    )}
                />

                <Controller
                    name='lastname'
                    control={control}
                    render={({ field }) => (
                        <TextInput
                            type='text'
                            placeholder={getText('forms.joinSession.fields.lastName.label')}
                            error={errors.lastname?.message}
                            {...field}
                        />
                    )}
                />
            </div>

            <SubmitButton label={getText('forms.joinSession.submit')} />
        </form>
    );
}

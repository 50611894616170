import React, { ReactElement, useEffect, useState } from 'react';
import PlusIcon from '../../components/icons/plus';
import useLocale from '../../hooks/use-locale';
import { InferType, object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Sizes from '../../constants/sizes';
import useGameState from '../../hooks/use-game-state';

export default function AddWord(): ReactElement {
    const { addWord } = useGameState();

    const [isExtended, setIsExtended] = useState(false);

    const { getText } = useLocale();

    const schema = object()
        .shape({
            text: string()
                .min(1, getText('forms.addWord.fields.word.error.minLength'))
                .max(15, getText('forms.addWord.fields.word.error.maxLength', 15))
                .required(getText('forms.addWord.fields.word.error.required')),
        })
        .required();

    const {
        register,
        handleSubmit: formHandleSubmit,
        reset,
        formState: { errors },
        setFocus,
    } = useForm({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema),
    });

    async function handleSubmit(values: InferType<typeof schema>): Promise<void> {
        addWord(values.text);
        setIsExtended(false);
        reset();
    }

    function handleOpenForm(): void {
        setIsExtended(true);
    }

    useEffect(() => {
        if (!isExtended) {
            return;
        }

        setFocus('text');
    }, [isExtended, setFocus]);

    if (!isExtended) {
        return (
            <button
                type='button'
                onClick={() => handleOpenForm()}
                className='mx-auto flex h-fit items-center space-x-2 rounded-xl bg-white p-2'
            >
                <PlusIcon width={Sizes.icon.small} height={Sizes.icon.small} />
                <span className='font-bold text-black'>{getText(`pages.game.addButton`)}</span>
            </button>
        );
    }

    return (
        <form className='mx-auto w-fit rounded-xl bg-white px-4 py-2' onSubmit={formHandleSubmit(handleSubmit)}>
            <input
                id='text'
                type='text'
                className='h-fit text-black outline-0'
                placeholder={getText(`pages.game.placeholder`)}
                maxLength={15}
                {...register('text')}
            />
            <button type='submit' className='font-bold text-black'>
                {getText(`pages.game.addInput`)}
            </button>
            {errors.text?.message && (
                <p className='text-xs text-red-500 '>
                    {(errors.text?.message as string) || getText(`pages.game.inputError`)}
                </p>
            )}
        </form>
    );
}

import { IconProps } from '../../types/icons';
import React, { ReactElement } from 'react';

export default function ArrowRight(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 12 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
        >
            <path
                d='M11.4166 11.2701L3.87697 18.8097C3.52148 19.153 3.04535 19.343 2.55113 19.3387C2.05692 19.3344 1.58417 19.1362 1.23469 18.7867C0.885216 18.4373 0.686983 17.9645 0.682689 17.4703C0.678394 16.9761 0.868381 16.4999 1.21173 16.1445L7.41869 9.93749L1.21173 3.73053C0.868382 3.37503 0.678395 2.8989 0.68269 2.40469C0.686985 1.91047 0.885217 1.43772 1.23469 1.08824C1.58417 0.738768 2.05692 0.540535 2.55114 0.536242C3.04535 0.531946 3.52148 0.721934 3.87697 1.06528L11.4166 8.60487C11.7699 8.95834 11.9684 9.43768 11.9684 9.93749C11.9684 10.4373 11.7699 10.9166 11.4166 11.2701Z'
                fill='black'
            />
        </svg>
    );
}

import React, { ReactElement, useEffect, useState } from 'react';

type AblyOutageStatus = {
    last_down_timestamp: number;
    last_down_utc: string;
    time_since_last_down_minutes: number;
};

export default function StatisticsUptimeBlock(): ReactElement {
    const [lastDown, setLastDown] = useState<Date>();
    const [now, setNow] = useState<Date>(new Date());

    async function init(): Promise<void> {
        const result = await fetch('https://ably.com/status/embed/last-outage.json', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const json = (await result.json()) as AblyOutageStatus;
        setLastDown(new Date(json.last_down_timestamp));
    }

    useEffect(() => {
        init();

        const interval = setInterval(() => {
            setNow(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='flex flex-col rounded-xl bg-company-darkGrey md:p-4  '>
            <p className='text-base'>Uptime since outage</p>
            <div className='flex justify-between md:grid md:grid-cols-2 md:grid-rows-2 md:gap-4'>
                {lastDown && (
                    <>
                        <div className='w-fit text-lg font-medium text-company-green md:text-2xl'>
                            {Math.floor((now.getTime() - lastDown.getTime()) / (1000 * 60 * 60 * 24))}
                            <span className='mt-auto ml-1 text-base font-light text-company-middleGrey md:hidden'>
                                Days
                            </span>
                            <p className='hidden text-base font-light leading-[10px] text-company-middleGrey md:block'>
                                Days
                            </p>
                        </div>
                        <div className='w-fit text-lg font-medium text-white md:text-2xl'>
                            {Math.floor((now.getTime() - lastDown.getTime()) / (1000 * 60 * 60)) % 24}
                            <span className='mt-auto ml-1 text-base font-light text-company-middleGrey md:hidden'>
                                h
                            </span>
                            <p className='hidden text-base font-light leading-[10px] text-company-middleGrey md:block'>
                                Hours
                            </p>
                        </div>
                        <div className='w-fit text-lg font-medium text-white md:text-2xl'>
                            {Math.floor((now.getTime() - lastDown.getTime()) / (1000 * 60)) % 60}
                            <span className='mt-auto ml-1 text-base font-light text-company-middleGrey md:hidden'>
                                m
                            </span>
                            <p className='hidden text-base font-light leading-[10px] text-company-middleGrey md:block'>
                                Minutes
                            </p>
                        </div>
                        <div className='w-fit text-lg font-medium text-white md:text-2xl'>
                            {Math.floor((now.getTime() - lastDown.getTime()) / 1000) % 60}
                            <span className='mt-auto ml-1 text-base font-light text-company-middleGrey md:hidden'>
                                s
                            </span>
                            <p className='hidden text-base font-light leading-[10px] text-company-middleGrey md:block'>
                                Seconds
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

import React, { ReactElement, useEffect, useState } from 'react';
import useMembers from '../../hooks/use-members';
import Class from '../../utils/classes';
import useLocale from '../../hooks/use-locale';
import useGlobeData from '../../hooks/use-globe-data';
import GlobeSelectionType from '../../types/enum/globe-selection';

export default function MemberCount(): ReactElement {
    const [expanded, setExpanded] = useState(false);
    const [playAnimation, setPlayAnimation] = useState(false);

    const users = useMembers();
    const { selection, clearSelection } = useGlobeData();

    const { getText } = useLocale();

    useEffect(() => {
        setPlayAnimation(true);
        setTimeout(() => {
            setPlayAnimation(false);
        }, 500);
    }, [users]);

    useEffect(() => {
        if (selection.state !== GlobeSelectionType.None) {
            setExpanded(false);
        }
    }, [selection]);

    function onClick(): void {
        setExpanded(!expanded);

        if (!expanded) {
            clearSelection();
        }
    }

    if (selection.state !== GlobeSelectionType.None) {
        return <div />;
    }

    return (
        <section className='relative mx-auto max-w-[265px]'>
            {expanded && (
                <div className='pointer-events-auto absolute top-0 left-0 -z-10 h-60 w-full -translate-y-full rounded-t-xl bg-company-darkGrey p-4'>
                    <div className='h-full overflow-y-scroll pb-5'>
                        {users.map((user) => (
                            <div key={user.id} className='py-4 text-white first:pt-0 last:border-b-0'>
                                <p className='w-full font-semibold antialiased'>{`${user.firstname} ${user.lastname}`}</p>
                                <div className='item-center flex space-x-2'>
                                    <div className='my-auto h-2 w-2 rounded-full bg-green-500' />
                                    <p>Online now</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div
                className={Class.classNames(
                    '-translate-y-1/2 rounded-full',
                    expanded ? 'border-8 border-company-darkGrey' : 'p-2',
                )}
            >
                <button
                    type='button'
                    className='pointer-events-auto z-50 flex w-full cursor-pointer space-x-2 rounded-full bg-white px-3 py-2 text-lg text-black'
                    onClick={() => onClick()}
                >
                    <div
                        className={Class.classNames(
                            'flex h-8 w-8 items-center rounded-full bg-company-green',
                            playAnimation ? 'animate-bounce' : '',
                        )}
                    >
                        <p className='m-auto rounded-full text-center font-bold text-white'>{users.length}</p>
                    </div>
                    <p className='my-auto pr-2 text-center'>
                        {users.length > 1
                            ? getText('sections.userCount.label.plural')
                            : getText('sections.userCount.label.singular')}
                    </p>
                </button>
            </div>
        </section>
    );
}

import React, { ReactElement } from 'react';
import useLocale from '../hooks/use-locale';
import JoinSessionWithSessionId from '../forms/join-session-with-session-id';

export default function NotFoundPage(): ReactElement {
    const { getText } = useLocale();

    return (
        <div className='m-auto my-10 flex w-full max-w-screen-xl flex-col justify-center md:h-full md:flex-row md:px-10 lg:space-x-10 lg:px-10'>
            <div className='my-auto h-fit w-full md:w-full'>
                <img className='relative mx-auto w-24 md:mx-0' src='/assets/ably-logo.png' alt='logo' />
                <img
                    className='relative -z-10 h-full w-full object-cover md:hidden'
                    src='/assets/globe-static-mobile.png'
                    alt='404 globe mobile'
                />
                <img
                    className='max-h-screen-1/2 relative -z-10 hidden h-full w-full object-contain md:block'
                    src='/assets/globe-static.png'
                    alt='404 globe desktop'
                />
            </div>
            <div className='mx-8 h-fit -translate-y-1/2 md:my-auto md:mx-0 md:-translate-y-0 lg:w-full lg:max-w-sm'>
                <div className='w-full rounded-xl bg-company-darkGrey p-10'>
                    <JoinSessionWithSessionId title={getText('pages.404.title')} copy={getText('pages.404.copy')} />
                </div>
            </div>
        </div>
    );
}

export const EdgeLocations = {
    IAD: {
        city: 'Washington',
        state: 'District of Columbia',
        country: 'United States',
        countryCode: 'US',
        count: 11,
        latitude: 38.944_499_97,
        longitude: -77.455_802_92,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    ORD: {
        city: 'Chicago',
        state: 'Illinois',
        country: 'United States',
        countryCode: 'US',
        count: 19,
        latitude: 41.978_611,
        longitude: -87.904_722,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    JFK: {
        city: 'New York',
        state: 'New York',
        country: 'United States',
        countryCode: 'US',
        count: 10,
        latitude: 40.639_801,
        longitude: -73.7789,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    ATL: {
        city: 'Atlanta',
        state: 'Georgia',
        country: 'United States',
        countryCode: 'US',
        count: 10,
        latitude: 33.6367,
        longitude: -84.428_101,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    LAX: {
        city: 'Los Angeles',
        state: 'California',
        country: 'United States',
        countryCode: 'US',
        count: 9,
        latitude: 33.942_501,
        longitude: -118.407_997,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    MIA: {
        city: 'Miami',
        state: 'Florida',
        country: 'United States',
        countryCode: 'US',
        count: 11,
        latitude: 25.793_199_539_184_57,
        longitude: -80.290_603_637_695_31,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    DFW: {
        city: 'Dallas-Fort Worth',
        state: 'Texas',
        country: 'United States',
        countryCode: 'US',
        count: 11,
        latitude: 32.896_801,
        longitude: -97.038_002,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    IAH: {
        city: 'Houston',
        state: 'Texas',
        country: 'United States',
        countryCode: 'US',
        count: 4,
        latitude: 29.984_399_795_532_227,
        longitude: -95.341_400_146_484_38,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    SFO: {
        city: 'San Francisco',
        state: 'California',
        country: 'United States',
        countryCode: 'US',
        count: 6,
        latitude: 37.618_999_481_201_17,
        longitude: -122.375,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    BOS: {
        city: 'Boston',
        state: 'Massachusetts',
        country: 'United States',
        countryCode: 'US',
        count: 5,
        latitude: 42.364_299_77,
        longitude: -71.005_203_25,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    DEN: {
        city: 'Denver',
        state: 'Colorado',
        country: 'United States',
        countryCode: 'US',
        count: 3,
        latitude: 39.861_698_150_635,
        longitude: -104.672_996_521,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    PDX: {
        city: 'Portland',
        state: 'Oregon',
        country: 'United States',
        countryCode: 'US',
        count: 2,
        latitude: 45.588_699_34,
        longitude: -122.597_999_6,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    SEA: {
        city: 'Seattle',
        state: 'Washington',
        country: 'United States',
        countryCode: 'US',
        count: 6,
        latitude: 47.448_889,
        longitude: -122.309_444,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    YTO: {
        city: 'Toronto',
        state: 'Canada',
        country: 'Canada',
        countryCode: 'CA',
        count: 3,
        latitude: 43.677_200_317_4,
        longitude: -79.630_599_975_599_99,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    MSP: {
        city: 'Minneapolis',
        state: 'Minnesota',
        country: 'United States',
        countryCode: 'US',
        count: 4,
        latitude: 44.882,
        longitude: -93.221_802,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    PHX: {
        city: 'Phoenix',
        state: 'Arizona',
        country: 'United States',
        countryCode: 'US',
        count: 2,
        latitude: 33.434_299_468_994_14,
        longitude: -112.012_001_037_597_66,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    QRO: {
        city: 'Queretaro',
        state: 'Mexico',
        country: 'Mexico',
        countryCode: 'MX',
        count: 2,
        latitude: 20.6173,
        longitude: -100.185_997,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    YUL: {
        city: 'Montréal',
        state: 'Canada',
        country: 'Canada',
        countryCode: 'CA',
        count: 2,
        latitude: 45.470_600_128_2,
        longitude: -73.740_798_950_2,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    PHL: {
        city: 'Philadelphia',
        state: 'Pennsylvania',
        country: 'United States',
        countryCode: 'US',
        count: 1,
        latitude: 39.871_898_651_123_05,
        longitude: -75.241_096_496_582_03,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    SLC: {
        city: 'Salt Lake City',
        state: 'Utah',
        country: 'United States',
        countryCode: 'US',
        count: 1,
        latitude: 40.788_398_742_675_78,
        longitude: -111.977_996_826_171_88,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    YVR: {
        city: 'Vancouver',
        state: 'Canada',
        country: 'Canada',
        countryCode: 'CA',
        count: 1,
        latitude: 49.193_901_062,
        longitude: -123.183_998_108,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    BNA: {
        city: 'Nashville',
        state: 'Tennessee',
        country: 'United States',
        countryCode: 'US',
        count: 2,
        latitude: 36.124_500_274_658_2,
        longitude: -86.678_199_768_066_4,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    DTW: {
        city: 'Detroit',
        state: 'Michigan',
        country: 'United States',
        countryCode: 'US',
        count: 2,
        latitude: 42.212_398_529_052_734,
        longitude: -83.353_401_184_082_03,
        region: 'North America',
        pricingRegion: 'United States, Mexico, & Canada',
    },
    FRA: {
        city: 'Frankfurt am Main',
        country: 'Germany',
        countryCode: 'DE',
        count: 17,
        latitude: 50.033_333,
        longitude: 8.570_556,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    LHR: {
        city: 'London',
        country: 'UK',
        countryCode: 'GB',
        count: 24,
        latitude: 51.4775,
        longitude: -0.461_389,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    CDG: {
        city: 'Paris',
        country: 'France',
        countryCode: 'FR',
        count: 9,
        latitude: 49.012_798,
        longitude: 2.55,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    MXP: {
        city: 'Milan',
        country: 'Italy',
        countryCode: 'IT',
        count: 9,
        latitude: 45.6306,
        longitude: 8.728_11,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    FCO: {
        city: 'Rome',
        country: 'Italy',
        countryCode: 'IT',
        count: 6,
        latitude: 41.800_277_8,
        longitude: 12.238_888_9,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    TXL: {
        city: 'Berlin',
        country: 'Germany',
        countryCode: 'DE',
        count: 5,
        latitude: 52.559_722,
        longitude: 13.287_778,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    MAD: {
        city: 'Madrid',
        country: 'Spain',
        countryCode: 'ES',
        count: 4,
        latitude: 40.471_926,
        longitude: -3.562_64,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    MRS: {
        city: 'Marseille',
        country: 'France',
        countryCode: 'FR',
        count: 4,
        latitude: 43.439_271_922,
        longitude: 5.221_424_102_78,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    AMS: {
        city: 'Amsterdam',
        country: 'The Netherlands',
        countryCode: 'NL',
        count: 4,
        latitude: 52.308_601,
        longitude: 4.763_89,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    DUS: {
        city: 'Düsseldorf',
        country: 'Germany',
        countryCode: 'DE',
        count: 3,
        latitude: 51.289_501,
        longitude: 6.766_78,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    HAM: {
        city: 'Hamburg',
        country: 'Germany',
        countryCode: 'DE',
        count: 3,
        latitude: 53.630_401_611_328,
        longitude: 9.988_229_751_586_9,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    MAN: {
        city: 'Manchester',
        country: 'UK',
        countryCode: 'GB',
        count: 5,
        latitude: 53.353_698_730_468_75,
        longitude: -2.274_950_027_465_820_3,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    MUC: {
        city: 'Munich',
        country: 'Germany',
        countryCode: 'DE',
        count: 4,
        latitude: 48.353_802,
        longitude: 11.7861,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    VIE: {
        city: 'Vienna',
        country: 'Austria',
        countryCode: 'AT',
        count: 3,
        latitude: 48.110_298_156_738,
        longitude: 16.569_700_241_089,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    ARN: {
        city: 'Stockholm',
        country: 'Sweden',
        countryCode: 'SE',
        count: 3,
        latitude: 59.651_901_245_117,
        longitude: 17.918_600_082_397,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    CPH: {
        city: 'Copenhagen',
        country: 'Denmark',
        countryCode: 'DK',
        count: 2,
        latitude: 55.617_900_848_389,
        longitude: 12.656_000_137_329,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    DUB: {
        city: 'Dublin',
        country: 'Ireland',
        countryCode: 'IE',
        count: 2,
        latitude: 53.421_299,
        longitude: -6.270_07,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    HEL: {
        city: 'Helsinki',
        country: 'Finland',
        countryCode: 'FI',
        count: 3,
        latitude: 60.317_199_707_031,
        longitude: 24.963_300_704_956,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    ATH: {
        city: 'Athens',
        country: 'Greece',
        countryCode: 'GR',
        count: 1,
        latitude: 37.936_401_367_2,
        longitude: 23.944_499_969_5,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    BRU: {
        city: 'Brussels',
        country: 'Belgium',
        countryCode: 'BE',
        count: 1,
        latitude: 50.901_401_519_800_004,
        longitude: 4.484_439_849_85,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    BUD: {
        city: 'Budapest',
        country: 'Hungary',
        countryCode: 'HU',
        count: 1,
        latitude: 47.429_76,
        longitude: 19.261_093,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    LIS: {
        city: 'Lisbon',
        country: 'Portugal',
        countryCode: 'PT',
        count: 1,
        latitude: 38.7813,
        longitude: -9.135_92,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    OSL: {
        city: 'Oslo',
        country: 'Norway',
        countryCode: 'NO',
        count: 2,
        latitude: 60.193_901_062_012,
        longitude: 11.100_399_971_008,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    OTP: {
        city: 'Bucharest',
        country: 'Romania',
        countryCode: 'RO',
        count: 1,
        latitude: 44.571_111_1,
        longitude: 26.085,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    PMO: {
        city: 'Palermo',
        country: 'Italy',
        countryCode: 'IT',
        count: 1,
        latitude: 38.175_999,
        longitude: 13.091,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    PRG: {
        city: 'Prague',
        country: 'Czech',
        countryCode: 'CZ',
        count: 1,
        latitude: 50.1008,
        longitude: 14.26,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    SOF: {
        city: 'Sofia',
        country: 'Bulgaria',
        countryCode: 'BG',
        count: 1,
        latitude: 42.696_693_420_410_156,
        longitude: 23.411_436_080_932_617,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    WAW: {
        city: 'Warsaw',
        country: 'Poland',
        countryCode: 'PL',
        count: 1,
        latitude: 52.165_833,
        longitude: 20.967_222,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    ZAG: {
        city: 'Zagreb',
        country: 'Croatia',
        countryCode: 'HR',
        count: 1,
        latitude: 45.742_900_848_4,
        longitude: 16.068_799_972_5,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    ZRH: {
        city: 'Zurich',
        country: 'Switzerland',
        countryCode: 'CH',
        count: 1,
        latitude: 47.464_699,
        longitude: 8.549_17,
        region: 'Europe',
        pricingRegion: 'Europe & Israel',
    },
    NRT: {
        city: 'Tokyo',
        country: 'Japan',
        countryCode: 'JP',
        count: 20,
        latitude: 35.764_702,
        longitude: 140.386_002,
        region: 'Asia',
        pricingRegion: 'Japan',
    },
    DEL: {
        city: 'New Delhi',
        country: 'India',
        countryCode: 'IN',
        count: 7,
        latitude: 28.5665,
        longitude: 77.103_104,
        region: 'Asia',
        pricingRegion: 'India',
    },
    ICN: {
        city: 'Seoul',
        country: 'Korea',
        countryCode: 'KR',
        count: 6,
        latitude: 37.469_100_952_148_44,
        longitude: 126.450_996_398_925_78,
        region: 'Asia',
        pricingRegion: 'Hong Kong, Indonesia, Philippines, Singapore, South Korea, Taiwan, & Thailand',
    },
    MAA: {
        city: 'Chennai',
        country: 'India',
        countryCode: 'IN',
        count: 7,
        // eslint-disable-next-line no-loss-of-precision
        latitude: 12.990_005_493_164_062,
        longitude: 80.169_296_264_648_44,
        region: 'Asia',
        pricingRegion: 'India',
    },
    SIN: {
        city: 'Singapore',
        country: 'Singapore',
        countryCode: 'SG',
        count: 6,
        latitude: 1.350_19,
        longitude: 103.994_003,
        region: 'Asia',
        pricingRegion: 'Hong Kong, Indonesia, Philippines, Singapore, South Korea, Taiwan, & Thailand',
    },
    KIX: {
        city: 'Osaka',
        country: 'Japan',
        countryCode: 'JP',
        count: 7,
        latitude: 34.427_299_499_511_72,
        longitude: 135.244_003_295_898_44,
        region: 'Asia',
        pricingRegion: 'Japan',
    },
    BOM: {
        city: 'Mumbai',
        country: 'India',
        countryCode: 'IN',
        count: 10,
        latitude: 19.088_699_340_8,
        longitude: 72.867_897_033_7,
        region: 'Asia',
        pricingRegion: 'India',
    },
    BLR: {
        city: 'Bangalore',
        country: 'India',
        countryCode: 'IN',
        count: 4,
        latitude: 13.1979,
        longitude: 77.706_299,
        region: 'Asia',
        pricingRegion: 'India',
    },
    HYD: {
        city: 'Hyderabad',
        country: 'India',
        countryCode: 'IN',
        count: 3,
        latitude: 17.231_318,
        longitude: 78.429_855,
        region: 'Asia',
        pricingRegion: 'India',
    },
    TPE: {
        city: 'Taipei',
        country: 'Taiwan',
        countryCode: 'TW',
        count: 3,
        latitude: 25.0777,
        longitude: 121.233_002,
        region: 'Asia',
        pricingRegion: 'Hong Kong, Indonesia, Philippines, Singapore, South Korea, Taiwan, & Thailand',
    },
    DMK: {
        city: 'Bangkok',
        country: 'Thailand',
        countryCode: 'TH',
        count: 10,
        latitude: 13.912_599_563_6,
        longitude: 100.607_002_258,
        region: 'Asia',
        pricingRegion: 'Hong Kong, Indonesia, Philippines, Singapore, South Korea, Taiwan, & Thailand',
    },
    CCU: {
        city: 'Kolkata',
        country: 'India',
        countryCode: 'IN',
        count: 2,
        latitude: 22.654_699_325_561_523,
        longitude: 88.446_701_049_804_69,
        region: 'Asia',
        pricingRegion: 'India',
    },
    CGK: {
        city: 'Jakarta',
        country: 'Indonesia',
        countryCode: 'ID',
        count: 2,
        latitude: -6.125_569_820_4,
        longitude: 106.655_998_23,
        region: 'Asia',
        pricingRegion: 'Hong Kong, Indonesia, Philippines, Singapore, South Korea, Taiwan, & Thailand',
    },
    KUL: {
        city: 'Kuala Lumpur',
        country: 'Malaysia',
        countryCode: 'MY',
        count: 2,
        latitude: 2.745_579_957_962,
        longitude: 101.709_999_084_47,
        region: 'Asia',
        pricingRegion: 'Hong Kong, Indonesia, Philippines, Singapore, South Korea, Taiwan, & Thailand',
    },
    MNL: {
        city: 'Manila',
        country: 'Philippines',
        countryCode: 'PH',
        count: 1,
        latitude: 14.5086,
        longitude: 121.019_997,
        region: 'Asia',
        pricingRegion: 'Hong Kong, Indonesia, Philippines, Singapore, South Korea, Taiwan, & Thailand',
    },
    HAN: {
        city: 'Hanoi',
        country: 'Vietnam',
        countryCode: 'VN',
        count: 1,
        latitude: 21.221_200_942_993_164,
        longitude: 105.806_999_206_542_97,
        region: 'Asia',
        pricingRegion: 'Hong Kong, Indonesia, Philippines, Singapore, South Korea, Taiwan, & Thailand',
    },
    SGN: {
        city: 'Ho Chi Minh',
        country: 'Vietnam',
        countryCode: 'VN',
        count: 1,
        latitude: 10.818_889,
        longitude: 106.651_944,
        region: 'Asia',
        pricingRegion: 'Hong Kong, Indonesia, Philippines, Singapore, South Korea, Taiwan, & Thailand',
    },
    SYD: {
        city: 'Sydney',
        country: 'Australia',
        countryCode: 'AU',
        count: 6,
        latitude: -33.946_098_327_636_72,
        longitude: 151.177_001_953_125,
        region: 'Australia & New Zealand',
        pricingRegion: 'Australia & New Zealand',
    },
    AKL: {
        city: 'Auckland',
        country: 'New Zealand',
        countryCode: 'NZ',
        count: 3,
        latitude: -37.008_098_602_299_995,
        longitude: 174.792_007_446,
        region: 'Australia & New Zealand',
        pricingRegion: 'Australia & New Zealand',
    },
    MEL: {
        city: 'Melbourne',
        country: 'Australia',
        countryCode: 'AU',
        count: 2,
        latitude: -37.673_302,
        longitude: 144.843_002,
        region: 'Australia & New Zealand',
        pricingRegion: 'Australia & New Zealand',
    },
    PER: {
        city: 'Perth',
        country: 'Australia',
        countryCode: 'AU',
        count: 1,
        latitude: -31.940_299_987_792_97,
        longitude: 115.967_002_868_652_34,
        region: 'Australia & New Zealand',
        pricingRegion: 'Australia & New Zealand',
    },
    GRU: {
        city: 'Sao Paulo',
        country: 'Brazil',
        countryCode: 'BR',
        count: 7,
        latitude: -23.435_556,
        longitude: -46.473_056,
        region: 'South America',
        pricingRegion: 'South America',
    },
    GIG: {
        city: 'Rio De Janeiro',
        country: 'Brazil',
        countryCode: 'BR',
        count: 2,
        latitude: -22.809_999_465_9,
        longitude: -43.250_556_945_8,
        region: 'South America',
        pricingRegion: 'South America',
    },
    BOG: {
        city: 'Bogota',
        country: 'Colombia',
        countryCode: 'CO',
        count: 2,
        latitude: 4.701_59,
        longitude: -74.1469,
        region: 'South America',
        pricingRegion: 'South America',
    },
    EZE: {
        city: 'Buenos Aires',
        country: 'Argentina',
        countryCode: 'AR',
        count: 2,
        latitude: -34.8222,
        longitude: -58.5358,
        region: 'South America',
        pricingRegion: 'South America',
    },
    SCL: {
        city: 'Santiago',
        country: 'Chile',
        countryCode: 'CL',
        count: 1,
        latitude: -33.393_001_556_396_484,
        // eslint-disable-next-line no-loss-of-precision
        longitude: -70.785_797_119_140_62,
        region: 'South America',
        pricingRegion: 'South America',
    },
    TLV: {
        city: 'Tel Aviv',
        country: 'Israel',
        countryCode: 'IL',
        count: 2,
        latitude: 32.011_398_315_429_69,
        longitude: 34.886_699_676_513_67,
        region: 'Middle East',
        pricingRegion: 'Europe & Israel',
    },
    BAH: {
        city: 'Manama',
        country: 'Bahrain',
        countryCode: 'BH',
        count: 2,
        latitude: 26.270_799_636_840_82,
        longitude: 50.633_598_327_636_72,
        region: 'Middle East',
        pricingRegion: 'South Africa, Kenya, & Middle East',
    },
    DXB: {
        city: 'Dubai',
        country: 'UAE',
        countryCode: 'AE',
        count: 1,
        latitude: 25.252_799_987_8,
        longitude: 55.364_398_956_3,
        region: 'Middle East',
        pricingRegion: 'South Africa, Kenya, & Middle East',
    },
    FJR: {
        city: 'Fujairah',
        country: 'UAE',
        countryCode: 'AE',
        count: 1,
        latitude: 25.112_222,
        longitude: 56.324_167,
        region: 'Middle East',
        pricingRegion: 'South Africa, Kenya, & Middle East',
    },
    CPT: {
        city: 'Cape Town',
        country: 'South Africa',
        countryCode: 'ZA',
        count: 1,
        latitude: -33.964_801_788_3,
        longitude: 18.601_699_829_1,
        region: 'Africa',
        pricingRegion: 'South Africa, Kenya, & Middle East',
    },
    JNB: {
        city: 'Johannesburg',
        country: 'South Africa',
        countryCode: 'ZA',
        count: 1,
        latitude: -26.1392,
        longitude: 28.246,
        region: 'Africa',
        pricingRegion: 'South Africa, Kenya, & Middle East',
    },
    NBO: {
        city: 'Nairobi',
        country: 'Kenya',
        countryCode: 'KE',
        count: 1,
        latitude: -1.319_239_974_02,
        longitude: 36.927_799_224_9,
        region: 'Africa',
        pricingRegion: 'South Africa, Kenya, & Middle East',
    },
    PVG: {
        city: 'Shanghai',
        country: 'China',
        countryCode: 'CN',
        count: 1,
        latitude: 31.143_400_192_260_742,
        longitude: 121.805_000_305_175_78,
        region: 'China',
        pricingRegion: 'China',
    },
    SZX: {
        city: 'Shenzhen',
        country: 'China',
        countryCode: 'CN',
        count: 1,
        latitude: 22.639_299_392_700_195,
        longitude: 113.810_997_009_277_34,
        region: 'China',
        pricingRegion: 'China',
    },
    ZHY: {
        city: 'Zhongwei',
        country: 'China',
        countryCode: 'CN',
        count: 1,
        latitude: 37.572_778,
        longitude: 105.154_444,
        region: 'China',
        pricingRegion: 'China',
    },
    PEK: {
        city: 'Beijing',
        country: 'China',
        countryCode: 'CN',
        count: 1,
        latitude: 40.080_101_013_183_594,
        longitude: 116.584_999_084_472_66,
        region: 'China',
        pricingRegion: 'China',
    },
    HKG: {
        city: 'Hong Kong',
        country: 'China',
        countryCode: 'HK',
        count: 4,
        latitude: 22.308_901,
        longitude: 113.915_001,
        region: 'China',
        pricingRegion: 'China',
    },
};

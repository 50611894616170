import React, { ReactElement } from 'react';
import { useDraggable } from '@dnd-kit/core';
import Class from '../../../utils/classes';

type Props = {
    id: string;
    index: number;
    children: ReactElement;
    className?: string;
    disabled: boolean;
};

export default function WordbarWordItem(props: Props): ReactElement {
    const { id, index, children, className, disabled } = props;

    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id,
        disabled,
        data: {
            index,
        },
    });

    return (
        <div
            ref={setNodeRef}
            data-index={id}
            {...attributes}
            {...listeners}
            {...{
                x: transform?.x,
                y: transform?.y,
                scalex: transform?.scaleX,
                scaley: transform?.scaleY,
            }}
            className={Class.classNames(className || '', disabled ? 'opacity-0' : '')}
        >
            {children}
        </div>
    );
}

WordbarWordItem.defaultProps = {
    className: '',
};

import { useContext } from 'react';
import { UserContext } from '../providers/user-provider';
import { User } from '../types/user';
import { UserStatus } from '../types/enum/user-status';

export type UserApi = User & {
    setName(firstname: string, lastname: string): void;
};

export default function useUser(): UserApi {
    const { user, setUser } = useContext(UserContext);

    function setName(firstname: string, lastname: string): void {
        localStorage.setItem('user-metadata', JSON.stringify({ firstname, lastname, id: user.id }));

        const data: User = {
            ...user,
            firstname,
            lastname,
            status: UserStatus.Online,
        };

        setUser(data);
    }

    return {
        ...user,
        setName,
    };
}

/* eslint-disable no-restricted-syntax */
import { GlobeSelection, UserRegionPoint } from '../../types/globe';
import { UserStatus } from '../../types/enum/user-status';

export default function GlobeUsersMarker(
    onClickRegion: (e: any) => void,
    onUserClick: (e: any) => void,
    onClose: (e: any) => void,
    onExpand: (e: any) => void,
    data: UserRegionPoint,
    selection: GlobeSelection,
    expanded: boolean,
): HTMLDivElement {
    const { users: allUsers, id } = data;
    const users = allUsers.filter((user) => user.status === UserStatus.Online);

    const container = document.createElement('div');
    const focus = selection.regionId === id;

    const el = document.createElement('div');
    el.style.color = '#000';
    el.style.display = 'flex';
    el.style.flexDirection = 'row';
    el.style.justifyContent = 'center';
    el.style.alignItems = 'center';
    el.style.borderRadius = '100%';

    if (focus) {
        const closeBtn = document.createElement('button');
        closeBtn.style.display = 'flex';
        closeBtn.style.width = '40px';
        closeBtn.style.height = '40px';
        closeBtn.style.borderRadius = '100%';
        closeBtn.style.background = `linear-gradient(35.26deg, #FFFFFF 16.71%, #DDDDDD 84.82%)`;
        closeBtn.style.cursor = 'pointer';
        closeBtn.style.color = '#000';
        closeBtn.style.alignItems = 'center';
        closeBtn.style.justifyContent = 'center';
        closeBtn.innerHTML = `
<svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
<path d='M7.01808 6.31812L11.3138 2.02223C11.5934 1.7428 11.5934 1.291 11.3138 1.01157C11.0344 0.732145 10.5826 0.732145 10.3032 1.01157L6.00729 5.30746L1.71154 1.01157C1.43198 0.732145 0.980311 0.732145 0.700881 1.01157C0.421321 1.291 0.421321 1.7428 0.700881 2.02223L4.99663 6.31812L0.700881 10.614C0.421321 10.8934 0.421321 11.3452 0.700881 11.6247C0.840138 11.764 1.02324 11.8341 1.20621 11.8341C1.38918 11.8341 1.57215 11.764 1.71154 11.6247L6.00729 7.32877L10.3032 11.6247C10.4426 11.764 10.6255 11.8341 10.8085 11.8341C10.9915 11.8341 11.1744 11.764 11.3138 11.6247C11.5934 11.3452 11.5934 10.8934 11.3138 10.614L7.01808 6.31812Z' fill='#03020D' stroke='#03020D' stroke-width='0.5'/>
</svg>
`;
        closeBtn.addEventListener('click', onClose);
        el.append(closeBtn);

        const len = users.length > 3 ? 3 : users.length;
        users.slice(0, len).forEach((user) => {
            const userEl = document.createElement('p');
            userEl.style.display = 'flex';
            userEl.style.alignItems = 'center';
            userEl.style.justifyContent = 'center';
            userEl.style.width = '40px';
            userEl.style.height = '40px';
            userEl.style.borderRadius = '100%';
            userEl.style.backgroundColor = user.id === selection.user?.id && !expanded ? '#32C361' : '#fff';
            userEl.style.color = '#000';
            userEl.style.fontSize = `14px`;
            userEl.style.fontWeight = 'bold';
            userEl.style.textAlign = 'center';
            userEl.textContent = `${user.firstname[0]}${user.lastname[0]}`.toUpperCase();
            userEl.style.marginLeft = '-10px';

            userEl.addEventListener('click', () => {
                onUserClick(user);
            });

            el.append(userEl);
        });

        if (users.length > 3) {
            const text = document.createElement('p');
            text.style.display = 'flex';
            text.style.alignItems = 'center';
            text.style.justifyContent = 'center';
            text.style.width = '40px';
            text.style.height = '40px';
            text.innerHTML = `+${users.length - 3}`.toUpperCase();

            const label = document.createElement('div');
            label.style.width = '40px';
            label.style.height = '40px';
            label.style.borderRadius = '100%';
            label.style.background = expanded
                ? `linear-gradient(35.26deg, #32C361 16.71%, #32C361 84.82%)`
                : `linear-gradient(35.26deg, #FFFFFF 16.71%, #DDDDDD 84.82%)`;
            label.style.color = expanded ? '#fff' : '#000';
            label.style.fontSize = `14px`;
            label.style.fontWeight = 'bold';
            label.style.textAlign = 'center';
            label.style.cursor = 'pointer';
            label.style.marginLeft = '-10px';

            label.append(text);
            label.addEventListener('click', (e) => {
                onExpand(e);
            });

            if (expanded) {
                const list = document.createElement('div');
                list.style.flexDirection = 'column';
                list.style.width = '100%';
                list.style.maxWidth = '160px';
                list.style.height = '200px';
                list.style.overflowY = 'scroll';
                list.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                list.style.pointerEvents = 'none';
                list.style.cursor = 'pointer';
                list.style.position = 'absolute';
                list.style.top = '50px';
                list.style.transform = 'translateX(-50%)';
                list.style.backgroundColor = '#fff';
                list.style.borderRadius = '10px';
                list.style.padding = '10px';
                list.style.pointerEvents = 'auto';

                users.slice(3, users.length).forEach((user, idx) => {
                    const item = document.createElement('div');
                    if (idx !== 0) {
                        item.style.marginTop = '10px';
                    }

                    const name = document.createElement('p');
                    name.innerHTML = user.id;
                    name.style.color = '#000';
                    name.style.fontSize = `14px`;
                    name.style.fontWeight = 'bold';
                    name.style.position = 'block';
                    name.style.textAlign = 'left';

                    name.textContent = `${user.firstname || ''} ${user.lastname || ''}`;

                    const userinfo = document.createElement('div');
                    userinfo.style.display = 'flex';
                    userinfo.style.flexDirection = 'row';
                    userinfo.style.width = '100%';
                    userinfo.style.height = '20px';
                    userinfo.style.position = 'block';
                    userinfo.style.textAlign = 'left';
                    userinfo.style.alignItems = 'center';

                    const status = document.createElement('div');
                    status.style.width = '10px';
                    status.style.height = '10px';
                    status.style.borderRadius = '100%';
                    status.style.backgroundColor = user.status === UserStatus.Online ? '#32C361' : 'red';

                    const statusText = document.createElement('p');
                    statusText.style.color = '#000';
                    statusText.style.fontSize = `14px`;
                    statusText.style.marginLeft = '5px';
                    statusText.style.position = 'block';
                    statusText.style.textAlign = 'left';
                    statusText.textContent = user.status === UserStatus.Online ? 'Online' : 'Offline';

                    userinfo.append(status);
                    userinfo.append(statusText);

                    item.append(name);
                    item.append(userinfo);

                    item.addEventListener('click', () => {
                        onUserClick(user);
                    });

                    list.append(item);
                });

                label.append(list);
            }

            el.append(label);
        }
    } else {
        const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svgElement.setAttribute('width', '65');
        svgElement.setAttribute('height', '57');
        svgElement.setAttribute('viewBox', '0 0 65 57');
        svgElement.setAttribute('fill', 'none');

        // Create the <g> elements with filters and circles and set their attributes
        const gElements = [
            { cx: '36.4322', cy: '28.7697', r: '20.0679', fill: 'url(#paint0_linear_1301_10448)' },
            { cx: '28.9439', cy: '28.7697', r: '20.0679', fill: 'url(#paint1_linear_1301_10448)' },
        ];

        for (let i = 0; i < 2; i += 1) {
            const gElement = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            gElement.setAttribute('filter', `url(#filter${i}_d_1301_10448)`);

            const circleElement = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            circleElement.setAttribute('cx', gElements[i].cx);
            circleElement.setAttribute('cy', gElements[i].cy);
            circleElement.setAttribute('r', gElements[i].r);
            circleElement.setAttribute('fill', gElements[i].fill);

            gElement.append(circleElement);
            svgElement.append(gElement);
        }

        const circleElement = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        circleElement.setAttribute('cx', '20.857');
        circleElement.setAttribute('cy', '28.7697');
        circleElement.setAttribute('r', '20.0679');
        circleElement.setAttribute('fill', 'url(#paint2_linear_1301_10448)');

        svgElement.append(circleElement);

        const defsElement = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
        const filterDefs = [
            {
                id: 'filter0_d_1301_10448',
                x: '8.36426',
                y: '0.701782',
                width: '56.1357',
                height: '56.1357',
                children: [
                    '<feFlood flood-opacity="0" result="BackgroundImageFix"/>',
                    '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>',
                    '<feOffset/>',
                    '<feGaussianBlur stdDeviation="4"/>',
                    '<feComposite in2="hardAlpha" operator="out"/>',
                    '<feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.0823529 0 0 0 0 0.168627 0 0 0 1 0"/>',
                    '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1301_10448"/>',
                    '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1301_10448" result="shape"/>',
                ],
            },
            {
                id: 'filter1_d_1301_10448',
                x: '0.875977',
                y: '0.701782',
                width: '56.1357',
                height: '56.1357',
                children: [
                    '<feFlood flood-opacity="0" result="BackgroundImageFix"/>',
                    '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>',
                    '<feOffset/>',
                    '<feGaussianBlur stdDeviation="4"/>',
                    '<feComposite in2="hardAlpha" operator="out"/>',
                    '<feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.0823529 0 0 0 0 0.168627 0 0 0 1 0"/>',
                    '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1301_10448"/>',
                    '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1301_10448" result="shape"/>',
                ],
            },
        ];

        const linearGradients = [
            {
                id: 'paint0_linear_1301_10448',
                x1: '25.9508',
                y1: '44.1656',
                x2: '47.9455',
                y2: '13.0513',
                stops: [
                    { offset: '0', stopColor: '#D6D6D6' },
                    { offset: '1', stopColor: '#B4B4B4' },
                ],
            },
            {
                id: 'paint1_linear_1301_10448',
                x1: '18.4626',
                y1: '44.1656',
                x2: '40.4572',
                y2: '13.0513',
                stops: [
                    { offset: '0', stopColor: '#D6D6D6' },
                    { offset: '1', stopColor: '#B4B4B4' },
                ],
            },
            {
                id: 'paint2_linear_1301_10448',
                x1: '10.3757',
                y1: '44.1656',
                x2: '32.3703',
                y2: '13.0513',
                stops: [
                    { offset: '0', stopColor: 'white' },
                    { offset: '1', stopColor: '#DDDDDD' },
                ],
            },
        ];

        for (const filterDef of filterDefs) {
            const filterElement = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
            filterElement.setAttribute('id', filterDef.id);
            filterElement.setAttribute('x', filterDef.x);
            filterElement.setAttribute('y', filterDef.y);
            filterElement.setAttribute('width', filterDef.width);
            filterElement.setAttribute('height', filterDef.height);
            filterElement.setAttribute('filterUnits', 'userSpaceOnUse');
            filterElement.setAttribute('color-interpolation-filters', 'sRGB');
            filterElement.innerHTML = filterDef.children.join('');
            defsElement.append(filterElement);
        }

        for (const gradientDef of linearGradients) {
            const gradientElement = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');
            gradientElement.setAttribute('id', gradientDef.id);
            gradientElement.setAttribute('x1', gradientDef.x1);
            gradientElement.setAttribute('y1', gradientDef.y1);
            gradientElement.setAttribute('x2', gradientDef.x2);
            gradientElement.setAttribute('y2', gradientDef.y2);

            for (const stop of gradientDef.stops) {
                const stopElement = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
                stopElement.setAttribute('offset', stop.offset);
                stopElement.setAttribute('stop-color', stop.stopColor);
                gradientElement.append(stopElement);
            }

            defsElement.append(gradientElement);
        }

        svgElement.append(defsElement);

        const textElement = document.createElement('p');
        textElement.style.position = 'absolute';
        textElement.style.top = '20px';
        textElement.style.left = '20px';
        textElement.style.transform = 'translate(-50%, 0)';
        textElement.style.width = '100%';
        textElement.style.textAlign = 'center';
        textElement.style.fontFamily = 'NEXT';
        textElement.style.fontStyle = 'normal';
        textElement.style.fontWeight = 'bold';
        textElement.style.fontSize = '14.9781px';
        textElement.style.lineHeight = '18px';
        textElement.style.textAlign = 'center';
        textElement.style.letterSpacing = '0.01em';
        textElement.style.color = '#03020D';
        textElement.style.zIndex = '120';
        textElement.innerHTML = `+${users.length}`;

        const multipleUsers = document.createElement('div');
        multipleUsers.style.position = 'relative';

        multipleUsers.append(svgElement);
        multipleUsers.append(textElement);

        el.append(multipleUsers);
    }

    el.style.pointerEvents = 'auto';
    el.style.cursor = 'pointer';

    el.addEventListener('click', () => {
        if (focus) {
            return;
        }

        onClickRegion(data);
    });

    el.style.zIndex = '110';

    container.append(el);

    return container;
}

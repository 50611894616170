/* eslint-disable max-len */
import React, { ReactElement } from 'react';
import { IconProps } from '../../types/icons';

export default function Chevron(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 14 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
        >
            <path d='M13.6218 5.80381L8.72367 0.905534C8.58385 0.765712 8.39749 0.688965 8.19879 0.688965C7.99986 0.688965 7.81362 0.765823 7.6738 0.905534L7.22908 1.35036C7.08937 1.48996 7.0124 1.67643 7.0124 1.87524C7.0124 2.07395 7.08937 2.2667 7.22908 2.4063L10.0866 5.2701H1.00813C0.59881 5.2701 0.275391 5.59054 0.275391 5.99997V6.62884C0.275391 7.03827 0.59881 7.39102 1.00813 7.39102H10.119L7.22919 10.2708C7.08948 10.4106 7.01251 10.592 7.01251 10.7908C7.01251 10.9894 7.08948 11.1735 7.22919 11.3132L7.67391 11.7566C7.81373 11.8964 7.99997 11.9726 8.1989 11.9726C8.3976 11.9726 8.58396 11.8954 8.72378 11.7556L13.6219 6.85743C13.7621 6.71716 13.8392 6.52993 13.8386 6.33089C13.8391 6.13119 13.7621 5.94385 13.6218 5.80381Z' />
        </svg>
    );
}

import React, { createContext, ReactElement, useMemo } from 'react';
import { WordSelection } from '../types/word';
import { useChannel } from '@ably-labs/react-hooks';
import { Types } from 'ably';
import useStateRef from 'react-usestateref';
import useSession from '../hooks/use-session';
import { SelectionType } from '../types/enum/selection-type';

type ContextType = {
    selection: WordSelection[];
};

export const WordMarkerContext = createContext<ContextType>({
    selection: [],
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function WordMarkerProvider(props: Props): ReactElement {
    const { children } = props;

    const {
        session: { id: sessionId },
    } = useSession();
    const [selection, setSelection, selectionRef] = useStateRef<WordSelection[]>([]);

    function addSelection(wordId: string, clientId: string, name: string): void {
        const newSelection = [...selectionRef.current, { clientId, name, wordId }];
        setSelection(newSelection);
    }

    function removeSelection(wordId: string): void {
        const newSelection = selectionRef.current.filter((v) => v.wordId !== wordId);
        setSelection(newSelection);
    }

    function handleSelectionUpdate(message: Types.Message): void {
        const { clientId, wordId, name, type } = message.data;

        switch (type) {
            case SelectionType.Select: {
                addSelection(wordId, clientId, name);
                break;
            }
            case SelectionType.Deselect: {
                removeSelection(wordId);
                break;
            }
            default: {
                console.info('Unknown selection type', type);
                break;
            }
        }
    }

    useChannel('word-selection', sessionId, handleSelectionUpdate);

    const value = useMemo(
        () => ({
            selection,
        }),
        [selection],
    );

    return <WordMarkerContext.Provider value={value}>{children}</WordMarkerContext.Provider>;
}

import React, { ReactElement, useState } from 'react';
import GameStateProvider from '../providers/game-state-provider';
import GameContainer from '../sections/game-page/game-container';
import AvatarStack from '../sections/game-page/avatar-stack';
import GameJoinsSessionModal from '../sections/game-page/game-joins-session-modal';
import VerticalMoreIcon from '../components/icons/vertical-more';
import useSession from '../hooks/use-session';

export default function Game(): ReactElement {
    const { isModerator } = useSession();
    const [openShareQrModal, setOpenShareQrModal] = useState(false);

    return (
        <GameStateProvider>
            <div className='flex h-screen w-screen items-center justify-center rounded-lg bg-[#F8FAFC]'>
                {openShareQrModal ? (
                    <GameJoinsSessionModal onClose={() => setOpenShareQrModal(false)} />
                ) : (
                    <>
                        <div className='fixed top-5 left-1/2 z-50 flex -translate-x-1/2 items-center md:hidden'>
                            <AvatarStack />
                        </div>
                        <div className='absolute left-1/2 top-1/2 flex h-full w-full -translate-y-1/2 -translate-x-1/2 justify-center space-x-5 md:h-fit'>
                            <div className='relative flex h-full w-full max-w-[900px] flex-row shadow-section md:h-fit md:w-full  md:rounded-xl'>
                                {isModerator && (
                                    <button
                                        type='button'
                                        className='absolute top-5 right-5 z-10 text-red-500'
                                        onClick={() => setOpenShareQrModal(!openShareQrModal)}
                                    >
                                        <VerticalMoreIcon className='h-6 w-6' />
                                    </button>
                                )}
                                <GameContainer />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </GameStateProvider>
    );
}

/* eslint-disable prefer-destructuring */
import { UserAgentData } from '../types/agent';

function parseUserAgent(userAgent: string): UserAgentData {
    const data: UserAgentData = {
        browser: '',
        version: '',
        os: '',
    };

    // Detect browser
    if (userAgent.includes('Firefox')) {
        data.browser = 'Mozilla Firefox';
    } else if (userAgent.includes('Edge')) {
        data.browser = 'Microsoft Edge';
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
        data.browser = 'Opera';
    } else if (userAgent.includes('Chrome')) {
        data.browser = 'Google Chrome';
    } else if (userAgent.includes('Safari')) {
        data.browser = 'Apple Safari';
    } else {
        data.browser = 'Unknown';
    }

    // Detect version
    switch (data.browser) {
        case 'Mozilla Firefox': {
            const match = userAgent.match(/Firefox\/([\d.]+)/);
            if (match) {
                data.version = match[1];
            }

            break;
        }
        case 'Microsoft Edge': {
            const match = userAgent.match(/Edge\/([\d.]+)/);
            if (match) {
                data.version = match[1];
            }

            break;
        }
        case 'Opera':
        case 'Google Chrome': {
            const match = userAgent.match(/(?:OPR|Chrome)\/([\d.]+)/);
            if (match) {
                data.version = match[1];
            }

            break;
        }
        case 'Apple Safari': {
            const match = userAgent.match(/Version\/([\d.]+)/);
            if (match) {
                data.version = match[1];
            }

            break;
        }
        default: {
            break;
        }
    }

    // Detect operating system
    if (userAgent.includes('Windows')) {
        data.os = 'Windows';
    } else if (userAgent.includes('Macintosh')) {
        data.os = 'MacOS';
    } else if (userAgent.includes('Linux')) {
        data.os = 'Linux';
    } else if (userAgent.includes('Android')) {
        data.os = 'Android';
    } else if (userAgent.includes('iPad')) {
        data.os = 'iPadOS';
    } else if (userAgent.includes('iPhone')) {
        data.os = 'iOS';
    } else {
        data.os = 'Unknown';
    }

    return data;
}

const UserAgentUtil = {
    parseUserAgent,
};

export default UserAgentUtil;

/* eslint-disable unicorn/no-null */
import React, {ReactElement, useState} from 'react';
import {
    AutoScrollActivator,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {restrictToWindowEdges, snapCenterToCursor} from '@dnd-kit/modifiers';
import Wordbar from './wordbar';
import GameCanvas from './game-canvas';
import useGameState from '../../hooks/use-game-state';
import useMousePosition from '../../hooks/use-mouse-position';
import useThrottling from '../../hooks/use-throttling';
import DndKitUtils from '../../utils/dndkit/container-hit-collision-detection';
import GameCanvasUtilites from '../../utils/game-canvas/game-canvas-utilites';
import WordMarkerProvider from '../../providers/word-marker-provider';
import useWordMarker from '../../hooks/use-word-marker';
import {Word} from '../../types/word';
import Class from '../../utils/classes';
import DynamicWords from '../../components/game/words/dynamic-words';
import WordUtils from '../../utils/words';
import useMembers from '../../hooks/use-members';
import AvatarStack from './avatar-stack';

export default function GameContainer(): ReactElement {
    const { allWords, appendWordToGameCanvas, removeWordFromGameCanvas, updateWordPosition } = useGameState();

    const { selectWord, unselectWord } = useWordMarker();
    const mousePosition = useMousePosition();

    const [selectedWord, setSelectedWord] = useState<Word>();

    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const members = useMembers();
    const { throttle } = useThrottling();

    async function handleDragEnd(event: DragEndEvent): Promise<void> {
        const wordId = event.active.id.toString().replace('id-game-canvas-', '');

        switch (event.over?.id) {
            case 'game-canvas': {
                // @ts-ignore
                const { width, height } = event.active.rect.current.translated;
                const pos = GameCanvasUtilites.MousePositionToCanvasCoordinates(
                    event.over?.rect,
                    {
                        width,
                        height,
                    },
                    {
                        x: mousePosition.x,
                        y: mousePosition.y,
                    },
                );

                appendWordToGameCanvas(event.active.id, pos.x, pos.y);
                break;
            }
            case 'wordbar': {
                removeWordFromGameCanvas(event.active.id);
                break;
            }
            default: {
                console.info('Dropped on nothing', event);
            }
        }

        unselectWord(wordId);
        // eslint-disable-next-line unicorn/no-useless-undefined
        setSelectedWord(undefined);
    }

    function handleDragMove(event: DragEndEvent): void {
        // @ts-ignore
        const { width, height } = event.active.rect.current.translated;

        if (event.over?.id === 'game-canvas') {
            const fn = throttle(updateWordPosition, members.length < 5 ? 100 : 100 * (members.length * 0.25));

            const pos = GameCanvasUtilites.MousePositionToCanvasCoordinates(
                event.over?.rect,
                {
                    width,
                    height,
                },
                {
                    x: mousePosition.x,
                    y: mousePosition.y,
                },
            );

            fn(event.active.id, pos.x, pos.y);
        }
    }

    async function handleDragStart(event: DragStartEvent): Promise<void> {
        const id = event.active.id.toString().replace('id-game-canvas-', '');

        selectWord(id);

        const word = allWords.find((v) => v.id === id.replace('wordbar-', ''));
        setSelectedWord(word);
    }

    return (
        <section className='relative h-screen w-full bg-black md:h-full md:rounded-xl md:bg-white'>
            <DndContext
                sensors={sensors}
                modifiers={[snapCenterToCursor, restrictToWindowEdges]}
                collisionDetection={(e) => DndKitUtils.collisionDetection.container('game-canvas', e)}
                onDragStart={(e) => handleDragStart(e)}
                onDragEnd={(e) => handleDragEnd(e)}
                onDragMove={(e) => handleDragMove(e)}
                autoScroll={{
                    activator: AutoScrollActivator.DraggableRect,
                    layoutShiftCompensation: false,
                }}
            >
                <div className='absolute top-5 left-1/2 z-50 hidden -translate-x-1/2 items-center md:block'>
                    <AvatarStack />
                </div>
                <WordMarkerProvider>
                    <GameCanvas selectedWordId={selectedWord?.id} />
                </WordMarkerProvider>
                <Wordbar />
                <DragOverlay dropAnimation={null}>
                    {selectedWord &&
                        (WordUtils.includeDynamicWordPattern(selectedWord?.text || '') ? (
                            <DynamicWords text={selectedWord?.text || ''} />
                        ) : (
                            <p
                                className={Class.classNames(
                                    'h-fit w-fit whitespace-nowrap rounded-full bg-gradient-to-b from-[#24ADD8] to-[#3D8DED] px-4 py-2 text-lg',
                                    selectedWord.id.slice(0, 2).includes('c$')
                                        ? 'from-[#C831BC] to-[#D5318E]'
                                        : 'from-[#24ADD8] to-[#3D8DED]',
                                )}
                            >
                                {selectedWord?.text}
                            </p>
                        ))}
                </DragOverlay>
            </DndContext>
        </section>
    );
}

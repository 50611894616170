import { Collision } from '@dnd-kit/core/dist/utilities/algorithms/types';
import { closestCorners, rectIntersection } from '@dnd-kit/core';

function containerCollisionDetection(targetId: string, { droppableContainers, ...args }: any): Collision[] {
    // First, let's see if the `game-canvas` droppable rect is intersecting
    const rectIntersectionCollisions = rectIntersection({
        ...args,
        droppableContainers: droppableContainers.filter(({ id }: { id: string }) => id === targetId),
    });

    // Collision detection algorithms return an array of collisions
    if (rectIntersectionCollisions.length > 0) {
        return rectIntersectionCollisions;
    }

    // Compute other collisions
    return closestCorners({
        ...args,
        droppableContainers: droppableContainers.filter(({ id }: { id: string }) => id !== targetId),
    });
}

const DndKitUtils = {
    collisionDetection: {
        container: containerCollisionDetection,
    },
};

export default DndKitUtils;

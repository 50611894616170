import React, { ReactElement, useEffect, useState } from 'react';
import { asyncInvoke } from '../utils/function';
import { useChannel } from '@ably-labs/react-hooks';
import Channels from '../types/enum/channels';
import LoadingPage from '../pages/loading';

type Props = {
    children: ReactElement | ReactElement[];
};

export default function EnsureValidSession(props: Props): ReactElement {
    const { children } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [channel] = useChannel(Channels.Sessions, () => {});

    async function checkSession(): Promise<void> {
        channel.history((err, page) => {
            if (!page) {
                window.location.pathname = '/404';
            }

            const sessionId = window.location.pathname.replace('/', '');

            // Dev-note: The following code ignore pagination.
            const result = page?.items.find((item) => item.name === sessionId);

            if (!result) {
                window.location.pathname = '/404';
                return;
            }

            setIsLoading(false);
        });
    }

    useEffect(asyncInvoke(checkSession), []);

    if (isLoading) {
        return <LoadingPage />;
    }

    return <>{children}</>;
}
